import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { DeleteAttribute } from '../api';

const useStyles = makeStyles({
    largeBtn: {
        padding: 10,
        width: 100
    },
    paperScrollPaper:{
      height:250
    }
});

const DeleteAttributeData = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async() => {
    const response=await DeleteAttribute([{"id":props.id, "tenantId":props.tenantId}]);
    props.renderHandler();
    setOpen(false);
  };

  return (
    <div >
      <IconButton onClick={handleClickOpen}>
            <DeleteIcon style={{color:"rgb(33, 218, 140)"}} />
      </IconButton>
      <Dialog
        className='deleteConfig'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title delete"
        aria-describedby="alert-dialog-description delete" >
        <DialogContent>  
            <Typography>Are you sure you want to delete the attribute data?</Typography>
            {/* <Typography variant="h5">{props.name}</Typography> */}
        <DialogActions>
          <Button onClick={handleClose} className={classes.largeBtn} variant="contained" color="black"> Close </Button>
          <Button onClick={handleDelete} className={classes.largeBtn} variant="contained" type="submit" color="primary"> Delete </Button>
        </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DeleteAttributeData;