/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container } from '@mui/material'
import { Box } from '@material-ui/core'
import FormControl from '@mui/material/FormControl'
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from '@mui/material'
import { StylesProvider } from "@material-ui/core/styles";
import {TextareaAutosize} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Dialog, DialogContent } from "@mui/material";
import "./tenantLabels.css"
import { padding } from '@mui/system'
import { Height } from '@material-ui/icons'
import {Paper} from '@mui/material'
import {Typography} from '@material-ui/core';
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import ConfigExport from '../tenantConfigv1/ConfigExport';
import BulkScreens from './BulkScreens'
const filter=createFilterOptions();

const useStyles = makeStyles((theme) => ({
    
    box : {
        display:'flex',
        alignItems:'center',
        justifyContent:'space-around',
        padding:"20px",
        marginBottom:"30px"
    },
    largeBtn: {
        padding: 10,
        width: 100,
      },
    ValuesData:{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "10px",
        padding: "10px",
    },
    root:{
        minHeight:"100vh"
    },
    textData:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    keyValue:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    btnHolder:{
        display:"flex",
        justifyContent:"center",
        alignItems:"flex-start",
        margin:"20px",
        height:"70px"
    }

  }));


const TenantLabelsV1 = () => {
    const classes=useStyles();
    const [labelsData,setLabelsData]=useState([]);
    const [screens,setScreens]=useState([]);
    const [selectedScreen,setSelectedScreen]=useState("");
    const [Attributes,setAttributes]=useState([]);
    const [selectedAttribute,setSelectedAttribute]=useState("");
    const [values,setValues]=useState("")
    const [color,setColor] =useState("#000000")
    const [object,setObject]=useState({})
    const [disable,setDisable]=useState(true);
    const [autoDisable,setAutoDisable]=useState(true);
    const [message,setMessage]=useState("")
    const [open,setopen]=useState(false);
    const [render,setRender]=useState(false);
    const [showBulk,setShowBulk]=useState(false);

    useEffect(async() => {
      try {
        const response=await fetch(window._env_.TENANT_CONFIG+`/tenant/getAllTenantLabels`,
        {
            method:'GET',
            credentials:'include',
        });
        const data=await response.json();
        console.log("labelsdata",data);
        setLabelsData(data);
        const screenArray=data.map((obj)=>{
            return obj.screen;
        })
        console.log("screens",screenArray);
        setScreens(screenArray)
      } catch (error) {
        
      }
    }, [render])

    function renderHandler(){
        setRender(!render);
    }

    const handleScreenChange=(newValue)=>{
        //console.log(newValue)
        setSelectedScreen(newValue);
        const data=labelsData.filter((obj)=>obj.screen===newValue)
        const att=Object.keys(data[0].mapper_json)
        //console.log(att);
        setAttributes(att);
        setValues("");
        setSelectedAttribute("");
        setAutoDisable(false);
    }

    const handleAttributeChange=(newValue)=>{
        if (typeof newValue === 'string') {
            setSelectedAttribute(newValue)
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            console.log("came here")
            setSelectedAttribute(newValue.inputValue)
          } else {
            setSelectedAttribute(newValue);
          }
        const data=labelsData.filter((obj)=>obj.screen===selectedScreen)
        console.log("here",data,newValue);
        var result=data[0].mapper_json[newValue];
        if(!result){
            setObject("");
            setValues("");
        }else{
            setObject(result);
            console.log(typeof(result),result);
            if(typeof(result)==='string'){
                setObject(result)
                setValues(result)
            }else{
                const resultStr=(JSON.stringify(result,null,2));
                setValues(resultStr);
            }
        }
        
    }

    const handleValueChange=(e)=>{
        if(!selectedScreen || !selectedAttribute){
            setDisable(true)
        }
        setDisable(false);
        setValues(e.target.value)
    }
    const handleSaveClick=async()=>{
        if(!selectedScreen || !selectedAttribute){

        }
        const data=labelsData.filter((obj)=>obj.screen===selectedScreen)
        const rowId=data[0].row_id;
        let value=values;
        try {
            value=JSON.parse(values);
        } catch (error) {
            value=values;
        }
        // setValues(value);
        data[0].mapper_json[selectedAttribute]=value;
        // if(typeof(values)==="object"){
        //     data[0].mapper_json[selectedAttribute]=JSON.parse(values);
        // }else{
            
        // }
        const updated=data[0].mapper_json;
        console.log("configval",updated)
        const requestOptions = {
            method: 'POST',
            credentials:"include",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({rowId:rowId,mapper_json:updated })
        };
        await fetch(window._env_.TENANT_CONFIG+'/tenant/updateTenantLabels', requestOptions)
        .then(response => response.json())
        .then(data=>{
            if(data.data){
                setMessage("Save Failed");
                setopen(true);
            }
            else{
                setMessage("Saved Successfully");
                setopen(true);
                //setRender(!render)
            }
        });
   }

    useEffect(() => {
        console.log("rerender")
    }, [object])
    const handleClose=()=>{
        setopen(false)
    }
    
  return (
    <>
    {showBulk?
    <Container className={classes.smallCont}>
    <BulkScreens renderHandler={renderHandler} setShowBulk={setShowBulk}/>
</Container>:
<>
<Dialog
                className="msg"
                onClose={e=>handleClose(e)}
                open={open}
                aria-labelledby="alert-dialog-title edit"
                aria-describedby="alert-dialog-description edit"
                id="custom message"
                >
               <DialogContent style={{margin:"auto",display:"flex"}}>
                <Typography variant="h6">{message}</Typography>
               </DialogContent>
                </Dialog>
    <Container>
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", margin:"10px 10px"}} >
                    <Typography variant="h6" style={{fontSize: "20px",color: "rgb(89, 89, 89)",fontWeight: 700}}>Tenant Label Mappers</Typography>
                    <div style={{display:'flex',flexDirection:'row'}}>      
                    <ConfigExport data={labelsData} type={"labels"} title={"Download Screens"} /> 
                    <Button variant="contained" style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"20px"
                          }} 
                          onClick={()=>setShowBulk(true)} >Bulk Screens Upload</Button>
                    <Button
                  disabled={disable}
                  onClick={handleSaveClick}
                  variant="contained"
                   style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"20px",
                          height:"38px",
                          width:"70px"
                          }}
                    >
                  Save
                </Button>
                </div>
        </div>
        <Container style={{height:"auto",boxShadow:"rgb(0 0 0 / 10%) 0px 0px 16px", background: "rgb(255, 255, 255)",borderRadius:"15px",padding:"20px",margin:"auto"}}>
        <div  className={classes.box}>
            <FormControl variant='outlined' sx={{backgroundColor:'#fff',width:"350px",padding:"10px",borderRadius:"5px"}}>
                <label>Screens</label>
                <Autocomplete
                //freeSolo
                id='Tenant-screens'
                options={screens}
                onChange={(e,newValue)=>handleScreenChange(newValue)}
                renderInput={(params) => <TextField {...params}  label="Screens"/>}
                />
            </FormControl>
            <FormControl variant='outlined' sx={{width:"350px",padding:"10px",borderRadius:"5px"}}>
                <label>Attributes</label>
                <Autocomplete
                //freeSolo
                id='Attributes'
                disabled={autoDisable}
                options={!Attributes ? [{label:"Loading...", id:0}] :Attributes }
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add ${inputValue}`,
                      });
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                       return `Add ${option.inputValue}`;
                    }
                    // Regular option
                    return option.title;
                  }}
                value={selectedAttribute}
                selectOnFocus
                clearOnBlur
                freeSolo
                handleHomeEndKeys
                onChange={(e,newValue)=>handleAttributeChange(newValue)}
                renderInput={(params) => <TextField {...params} disabled={autoDisable} label="Attributes" />}
                />
            </FormControl>
        </div>
        <div>
            <Paper style={{width:"900px",margin:"auto",padding:"20px"}}>
            <div style={{display:"flex",justifyContent:"center", margin:"10px auto"}}>
            <label>Values</label>
            </div>
            <div className={classes.textData}>
                <TextareaAutosize 
                    aria-label="minimum height"
                    spellCheck={false}
                    minRows={10}
                    value={values} 
                    onChange={(prop)=>handleValueChange(prop)} 
                    style={{ width: 500, marginBottom:"20px",padding:"20px",color:`${color}` }}
                />
            </div>
            </Paper>
        </div>
        {/* <div className={classes.btnHolder}>
                
        </div> */}
        </Container>
    </Container>
    </>}
    </>
  )
}

export default TenantLabelsV1