import React from "react";
import { useState,useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import { useAuthState } from "@bamboobox/b2logincheck";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import "./editResources.css"
import { updateRoleToResources } from "../../api";
import { width } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  largeBtn: {
    width: 140,
  },
  root : {
  },
  formControl: {
    //margin: theme.spacing(1),
    margin:"0.8rem",
    //width: 500,
    marginLeft: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogContent:{
    margin: "auto",
    marginTop: "30px",
    //marginLeft:"50px"
  }
}));

export default function EditResources(props) { 
    //console.log("here i am",props)
  const classes = useStyles();
  const [values,setValues]=useState([]);
  const [selectedResources,setSelectedResources]=useState([]);
  const [open, setOpen] = React.useState(false);
  const [resourceOptions,setResourceOptions]=useState([]);
  const [roleName,setRoleName]=useState("");

  const handleClose = (e) => {
    if(e._reactName === "onClick"){
      setOpen(false);
    }
    setValues(props.data)
    
  };

  const handleChange=(prop)=>{
    //console.log(prop.target.value)
    console.log(prop);
    setSelectedResources(prop);
  }

  const handleClickOpen=async()=>{
    setSelectedResources(props.data?.resources)
    setValues(props?.data)
    setRoleName(props?.data?.roleName);
    setResourceOptions(props?.resources)
    setOpen(true);
  }

  const handleSaveClick=async()=>{
    values.resources=selectedResources;
    values.roleName=roleName;
    console.log("resources to save",values,selectedResources);
    setValues(values);
    try {
      const response=await updateRoleToResources(values);
      props.renderHandler();
      props.setMessage("Updated successfully")
      props.setOpen(true);
      
    } catch (error) {
      props.setMessage("Unable to update try again")
      props.setOpen(true)
    }
    
  }

  const optionDisabled=(option)=>{
    const names=selectedResources.map((row)=>{return row.resourceOperationId});
    console.log(names.includes(option.resourceOperationId))
    return names.includes(option.resourceOperationId);
  }

  return (
    <div>
        <IconButton onClick={handleClickOpen}>
            <EditIcon style={{color:"rgb(33, 218, 140)"}}  />
        </IconButton>
      <Dialog
        className="editResource"
        open={open}
        onClose={e=>handleClose(e)}
        aria-labelledby="alert-dialog-title edit"
        aria-describedby="alert-dialog-description edit"
        disableEscapeKeyDown={true}
      >
        <div className={classes.dialogContent}>
        <DialogTitle id="alert-dialog-title" style={{padding:"0px 24px "}}>{"Edit Role-resources"}</DialogTitle>
        <DialogContent  >
          <DialogContentText id="alert-dialog-description">
            <form >
              <div>
                <FormControl variant="outlined" >
                 <label style={{color:"#212121"}}>RoleName</label>
                 <TextField
                    value={roleName}
                    id="outlined-disabled"
                    //defaultValue={values.roleName}
                    onChange={e=>setRoleName(e.target.value)}
                    style={{width:300}}
                />  
                </FormControl>
              </div>
              <FormControl variant="outlined" >
                   <div>
                   <label style={{color:"#212121"}}>Resources</label>
                   <Autocomplete
                    multiple
                    freeSolo
                    limitTags={4}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    onChange={(e,newValue)=>handleChange(newValue)}
                    id="multiple-limit-tags"
                    options={!resourceOptions ? [{label:"Loading...", id:0}] :resourceOptions}
                    getOptionLabel={(option) => option.resourceName+" : "+option.operationName}
                    value={selectedResources}
                    getOptionDisabled={(option)=>optionDisabled(option)}
                    sx={{width:500}}
                    renderInput={(params) => (
                    <TextField {...params} variant="outlined"/>
                )}
                /> 
                   </div>
              </FormControl>
              <DialogActions style={{margin:"auto",marginLeft:"0px",marginTop:"20px",justifyContent:"space-around" ,width:"400px"}}>
                <Button
                className={classes.largeBtn}
                  onClick={handleClose}
                  variant="contained"
                  style={{  fontSize: "12px",
                          backgroundColor: "#DDDDDD",
                          borderColor: "rgb(33, 218, 140)",
                          color: "black",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}
                >
                  Close
                </Button>
                <Button
                className={classes.largeBtn}
                  onClick={(e)=>handleSaveClick(e)}
                  variant="contained"
                  style={{  fontSize: "12px",
                  backgroundColor: "rgb(33, 218, 140)",
                  borderColor: "rgb(33, 218, 140)",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "18px",
                  marginRight:"10px",
                  padding:"8px 10px"
              }}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
