import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/styles';
import { useAuthState } from '@bamboobox/b2logincheck';
import { Button } from '@mui/material';
import { Container, Typography, IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { getAttributeData } from '../api';
import EditAttribute from "./EditAttributeData";
import NewAttributeData from "./NewAttributeData";
import DeleteAttributeData from "./DeleteAttributeData";
import DownloadAttributeData from "./DownloadAttributeData";
import ExportAttributeData from "./HandleBulkAttributeData";

const useStyles = makeStyles((theme) => ({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1em',
      alignItems: 'center'
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    smallCont: {
      height: 450,
      width: '100%',
      margin: '0 1em 1em'
    },
  }));

const Attribute = () => {
    const [rows, setRows] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [render, setRender] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [showBulk, setShowBulk] = useState(false);
    const userData = useAuthState().user;
    const tenantId = userData.tenantId;
    const classes = useStyles();

    const columns = [
      {
          field: 'parent', headerName: 'Parent', sortable: false, width: 250
      },{
          field: 'child', headerName: 'Child', sortable: false, width: 250
      }, {
          field: 'parent_value', headerName: 'Parent Value', sortable: false, width: 250
      },{
          field: 'child_value', headerName: 'Child Value', sortable: false, width: 250
      },
      {
          field: 'actions', headerName: 'Actions', sortable: false, width: 150,
          renderCell: (params) => {
            return (
              <>
                <EditAttribute 
                  renderHandler = {renderHandler}
                  id = {params.row.id}
                  parent = {params.row.parent}
                  child = {params.row.child}
                  parent_value = {params.row.parent_value}
                  child_value = {params.row.child_value}
                  tenantId = {params.row.tenantId}
                />
                 <DeleteAttributeData
                  renderHandler = {renderHandler}
                  id = {params.row.id}
                  tenantId = {params.row.tenantId}
                />  
              </>
            )
          }
      }
    ]
    
    function renderHandler() {
      setRender(!render);
    }  

    useEffect(async () => {
      setIsloading(true);
      try {
        const response = await getAttributeData(tenantId);
        const data = response?.data?.data;
        setRows(data);
        setIsloading(false);
      } catch (err) {
        console.log(err);
      }
    },[render])

    return (
        <>
            {showBulk ? (
              <Container className={classes.smallCont}>
              <ExportAttributeData renderHandler={renderHandler} setShowBulk={setShowBulk} />
              </Container>
            ) : (
            <Container>
            <div className={classes.header} style={{ margin: '10px 10px' }}>
            <Typography variant="h6" style={{
                overflowY: 'hidden',
                fontSize: '20px',
                color: 'rgb(89, 89, 89)',
                fontWeight: 700
              }}> Attributes </Typography>
            <div className={classes.buttons}>            
            <NewAttributeData renderHandler={renderHandler} variant="contained" color="primary" style={{
                  fontSize: '12px',
                  backgroundColor: 'rgb(33, 218, 140)',
                  borderColor: 'rgb(33, 218, 140)',
                  color: 'rgb(255, 255, 255)',
                  borderRadius: '18px'
                }}> Create New Attribute Data </NewAttributeData> 
            <Button
                variant="contained"
                color="primary"
                style={{
                  fontSize: '12px',
                  backgroundColor: 'rgb(33, 218, 140)',
                  borderColor: 'rgb(33, 218, 140)',
                  color: 'rgb(255, 255, 255)',
                  borderRadius: '18px',
                  marginRight: '20px',
                  marginLeft: '20px'
                }} onClick={() => setShowBulk(true)}> Bulk Attribute Data Upload </Button>    
            <DownloadAttributeData data={rows} type={'JSON'}/>   
            </div>  
            </div>
            <Container style={{height:"640px",boxShadow:"rgb(0 0 0 / 10%) 0px 0px 16px", background: "rgb(255, 255, 255)",borderRadius:"15px" }}>
                <DataGrid getRowId={(row) => row.id} rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} disableSelectionOnClick/>
            </Container>
            </Container>   
          )}           
        </>
    )
}   

export default Attribute;