import './CreateTenant.css'
import { Paper, Button, TextField, Alert, checkboxClasses, Checkbox, FormControlLabel, createTheme, Divider } from '@mui/material'
import { useState ,useEffect} from "react"
import validator from 'validator'
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { green } from '@mui/material/colors';
import { color } from '@mui/system';
import { toast } from "react-toastify";
import { useAuthState } from "@bamboobox/b2logincheck";
import "./toast.css"
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fefefe",
        },
    },
});

const CreateTenant = () => {
    const logindata = useAuthState();
    const [name, setTenantName] = useState("")
    const [domain, setTenantDomain] = useState("")
    const [username, setUserName] = useState("")
    const [useremail, setUserEmail] = useState("")
    const [department, setDepartment] = useState("")
    const [superUser, setSuperUser] = useState(false)
    
    

    
    const [emailError, setEmailError] = useState('')

    toast.configure({
        autoClose: 6000,
        draggable: true,
        hideProgressBar: false,
        position: toast.POSITION.BOTTOM_LEFT,
      });

    const validateEmail = (e) => {
    //var email = 
  
    if (validator.isEmail(e.target.value)) {
      setEmailError('')
     
    } else {
      setEmailError('Enter valid Email!')
    
    }
    setUserEmail(e.target.value)

  }
    function getFormData(e) {
        e.preventDefault();
        let loginUserId=logindata.user.userUuid
        console.log("loginUserId..........",)
        let data = { name, domain, username, useremail, department, superUser,loginUserId }
        const url = window._env_.LOGIN_API_URL+"/register/register-newtenant";
    if (validator.isEmail(useremail)) {
        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(data)
        }).then((result) => {
            let a = result.json()

            return a
        }).then((a)=> {
            console.log("data uploaded", a)
            if(a.message ==='The tenant is already registerd'){
                toast.error(<Alert severity="error">{a.message}</Alert>, {
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true})
            }else{
                toast.success(<Alert severity="success">{true} Tenant Create Successfully</Alert>, {
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
                
            }
        })
        setTenantName('')
        setTenantDomain('')
        setUserName('')
        setUserEmail('')
        setSuperUser(false)
        setDepartment('')
        setEmailError('Valid Email :)')
       
      }else {
        setEmailError('Enter valid Email!')
      
      }

        // console.log(name, domain, username, useremail, department, superUser)
        // fetch("http://test.bamboobox.in:3002/register/register-newtenant", {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(data)
        // }).then((result) => {
        //     console.log("data uploaded")
        // })
        // setTenantName('')
        // setTenantDomain('')
        // setUserName('')
        // setUserEmail('')
        // setSuperUser(false)
        // setDepartment('')

    }
    

    // const submitAndBack = (e) => {
    //     let data = { name, domain, username, useremail, department, superUser }
    //     console.log(name, domain, username, useremail, department, superUser)
    //     fetch("http://test.bamboobox.in:3002/register/register-newtenant", {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(data)
    //     }).then((result) => {
    //         console.log("data uploaded")
    //     })
    //     window.open('http://test.bamboobox.in:3000', "_self")
    // }
    return (
        <ThemeProvider theme={theme}>
            <form onSubmit={getFormData}>
            <Paper elevation={8} style={{
                marginLeft: "206px",
                marginRight: "206px",
                marginTop: "36px",
                marginBottom: "36px",
                borderRadius: "24px",
                width: "1022px",
                height: "663px",
                padding: "24px 32px"
            }}>
           
                <h5>Create a New Tenant</h5><br />
                <h5>Tenant Details</h5>
                <div className="detailbox" >
                    <div className='insideDetail'>
                        
                    <span className='textlable'>Name<span style={{color:"red"}}>*</span></span><TextField required autocomplete="false" placeholder='Company' size="small" variant="outlined" value={name} onChange={(e) => setTenantName(e.target.value)} errorMessage={"Name is not valid"} style={{border:" 1px solid #DBDBDB",borderRadius:"5px"}}  sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }} />
                    </div>
                    
                    <div className='insideDetail'>
                        <span className='textlable'>Domain<span style={{color:"red"}}>*</span></span><TextField required placeholder="company.com" size="small" variant="outlined" value={domain} onChange={(e) => setTenantDomain(e.target.value)} style={{border:" 1px solid #DBDBDB",borderRadius:"5px"}} sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }} />
                    </div>
                </div>
            
                <Divider /><br />
                <h5>Admin Details</h5>
                <div className="detailbox" >
                    <div className='insideDetail'>

                        <span className='textlable'>User Name<span style={{color:"red"}}>*</span></span><TextField required placeholder="Mark Henry" size="small" variant="outlined" value={username} onChange={(e) => setUserName(e.target.value)} style={{border:" 1px solid #DBDBDB",borderRadius:"5px"}} sx={{
                            
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }} />
                        <FormControlLabel style={{marginLeft:"10px"}}
                            label="Super User"
                            size="small"
                            control={
                                <Checkbox
                                    checked={superUser}
                                    onChange={(e) => setSuperUser(!superUser)}
                                />
                            }
                        />
                    </div></div>
                    
                    <div className="detailbox" >
                    <div className='insideDetail'>
                        <span className='textlable'>User Email<span style={{color:"red"}}>*</span></span><TextField required placeholder="henry.mark@company.com" variant="outlined" size="small" type="email" value={useremail} onChange={(e) => validateEmail(e)} style={{border:" 1px solid #DBDBDB",borderRadius:"5px"}} sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                         }} />
                    </div>
                    
                    <div className='insideDetail'>
                        <span className='textlable'>Department </span><TextField placeholder="Marketing" variant="outlined" size="small" value={department} onChange={(e)=>{setDepartment(e.target.value)}} style={{border:" 1px solid #DBDBDB",borderRadius:"5px"}} sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }} /></div></div>
                <div style={{ "display": "flex", "float": "right" }}>
                    <Button 
                        variant="contained" size="large" type="submit"  style={{
                            background: '#21DA8C',
                            fontSize: "15px",
                            borderRadius: "18px"
                        }}>Submit</Button>

                    {/* <Button
                        variant="contained" type="submit" onClick={(e)=>{submitAndBack(e)}} style={{
                            background: '#21DA8C',
                            fontSize: "15px",

                            borderRadius: "18px"
                        }}>Submit</Button> */}
                </div>

            </Paper>
            </form>
        </ThemeProvider>
    )
}

export default CreateTenant