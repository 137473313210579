import React from 'react'
import { Button } from '@mui/material';
import { useAuthState } from '@bamboobox/b2logincheck';

const ConfigExport = (props) => {
    const logindata=useAuthState();
    const Id=logindata.user.tenantId;
    const handleTemplateDownload = () => {
        let dataStr =
          "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(props.data));
        let downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", props.type+Id+".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
      };
      return (
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Button
          variant="contained"
          onClick={handleTemplateDownload}
          style={{  fontSize: "12px",
          backgroundColor: "rgb(33, 218, 140)",
          borderColor: "rgb(33, 218, 140)",
          color: "rgb(255, 255, 255)",
          borderRadius: "18px",
          marginRight:"20px"
          }} 
        >
          {props.title}
        </Button>
        </div>
      );
}

export default ConfigExport