import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {FormControl} from '@mui/material/';
import {TextField} from "@mui/material";
import { makeStyles } from '@material-ui/styles';
import EditIcon from "@material-ui/icons/Edit";
import '../tenantConfig/tenantConfigJsonV1/editConfigJson.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  largeBtn: {
    padding: 10,
    width: 140,
  },
  // root : {
  //   width: "500px",
  //   marginLeft:45
  // },
  formControl: {
    //margin: theme.spacing(1),
    margin:"0.8rem",
    marginLeft: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formRoot:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-around",
    width:"100%",
    margin:"40px auto"
  },
  textRoot:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    width:"80vw",
    margin:"20px auto"
  },paraV:{
    color:"#ff0000",
    display:"block"
  },
  paraH:{
    display:"none"
  },
}));

export default function EditAttribute(props) {
  const [open, setOpen] = useState(false);
  const [parentValue,setParentValue]=useState(props.parent);
  const [childValue,setChildValue]=useState(props.child);
  const [childValueValue,setChildValueValue]=useState(props.child_value);
  const [parentValueValue,setParentValueValue]=useState(props.parent_value);
  const classes=useStyles();

  const handleSaveClick = async () => {
    if(!parentValue || !childValue || !childValueValue || !parentValueValue){
      alert("Please fill in all attribute fields");
    } else {
      const requestOptions = {
        method: 'POST',
        credentials:"include",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id:props.id, parent:parentValue, child:childValue, parent_value:parentValueValue, child_value:childValueValue, tenantId:props.tenantId })
    };
    await fetch(window._env_.METADATA_URL + '/attribute/updateAttributeData', requestOptions)
    .then(response => response.json());
    props.renderHandler();
    setOpen(false);
    }
   
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onParentValueChange = (e) => {
    try{
        // JSON.parse(e.target.value);
        // setDisable(false);
    } catch (error) {
        // setDisable(true);
        console.log(error);
    }
    setParentValueValue(e.target.value);
  }

  const onChildValueChange = (e) => {
    try {
      // JSON.parse(e.target.value);
      // setDisable(false);
    } catch (error) {
      // setDisable(true);
      console.log(error);
    }
    setChildValueValue(e.target.value);
  }

  const onParentChange = (e) => {
    try{
        // JSON.parse(e.target.value);
        // setDisable(false);
    } catch (error) {
        // setDisable(true);
        console.log(error);
    }
    setParentValue(e.target.value);
  }

  const onChildChange = (e) => {
    try {
        // JSON.parse(e.target.value);
        // setDisable(false);
    } catch (error) {
        // setDisable(true);
        console.log(error);
    }
    setChildValue(e.target.value);
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
            <EditIcon style={{color:"rgb(33, 218, 140)"}} />
      </IconButton>
      <Dialog
        className='full'
        sx={{height:"100vh",width:"100vw"}}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition} >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon style={{color:"rgb(33, 218, 140)"}} />
            </IconButton>
            <Typography variant="h6" style={{fontSize: "20px",color: "rgb(89, 89, 89)",fontWeight: 700}}>Edit Attribute Data</Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.formRoot}>
                <FormControl variant="outlined" className={classes.formControl} >
                 <label style={{color:"#212121"}}>Parent</label>
                 <TextField
                    id="outlined-disabled"
                    defaultValue={props.parent}
                    style={{width:300}}
                    onChange = {(e) => onParentChange(e)}
                />  
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <label style={{color:"#212121"}}>Child</label>
                    <TextField
                        id="outlined-disabled"
                        defaultValue={props.child}
                        style={{width:300}}
                        onChange = {(e) => onChildChange(e)}
                    />
                </FormControl>
                </div>
                <div className={classes.formRoot}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <label style={{color:"#212121"}}>Parent Value</label>
                    <TextField
                        id="outlined-disabled"
                        defaultValue={props.parent_value}
                        style={{width:300}}
                        onChange = {(e) => onParentValueChange(e)}
                    />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <label style={{color:"#212121"}}>Child Value</label>
                    <TextField
                        id="outlined-disabled"
                        defaultValue={props.child_value}
                        style={{width:300}}
                        onChange = {(e) => onChildValueChange(e)}
                    />
                </FormControl>
                </div>
        
        <Button onClick={handleSaveClick}
                className={classes.largeBtn}
                variant="contained"
                type="button"
                // disabled={disable}
                style={{ fontSize: "15px",
                backgroundColor: "rgb(33, 218, 140)",
                borderColor: "rgb(33, 218, 140)",
                color: "rgb(255, 255, 255)",
                borderRadius: "18px",
                margin:"20px auto"}}>Save</Button>
      </Dialog>
    </div>
  );
}
