import React, { useState } from "react";
import { Button } from '@mui/material'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import { useAuthState } from "@bamboobox/b2logincheck";
import { TextField, Select, MenuItem } from "@mui/material";
import FormHelperText from '@mui/material/FormHelperText';
import { newIcpData } from "../api";


const useStyles = makeStyles(theme => ({
  form:{
    display:"flex",
    flexDirection:"column",
    marginLeft:"20px"
  },
  largeBtn: {
      width: 120
  },
  formControl: {
    margin: theme.spacing(1),
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginLeft: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonsHolder:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginTop:"20px"
  },
  col:{
    marginBottom:"10px"
  },
  dialogContent:{
    margin: "auto",
    marginTop: "15px",
  },
 }));

export default function NewIcpRules(props) {
  const classes = useStyles();
  const userData = useAuthState().user;
  const tenantId = userData.tenantId;
  const [open, setOpen] = React.useState(false);
  
  const [icp, setIcp] = useState('');
  const [icpError, setIcpError] = useState(false);  
  const [icpText, setIcpText] = useState('');
  
  const [stage, setStage] = useState('');
  const [stageError, setStageError] = useState(false);  
  const [stageText, setStageText] = useState('');  

  const [rule, setRule] = useState('');
  const [ruleError, setRuleError] = useState(false);
  const [ruleText, setRuleText] = useState('');

  const [priorityOrder, setpriorityOrder] = useState('');
  const [priorityOrderError, setpriorityOrderError] = useState(false);
  const [priorityOrderErrorText, setpriorityOrderErrorText] = useState('');

  const [type, setType] = useState('');
  const [typeError, setTypeError] = useState(false);  
  const [typeText, setTypeText] = useState(''); 

  const handleSave = async() => {
    if(!icp || !rule || !stage || !priorityOrder || !type) {
      setIcpError(true);
      setIcpText('This is a required field');
      setRuleError(true);
      setRuleText('This is a required field')
      setStageError(true);
      setStageText('This is a required field')
      setpriorityOrderError(true);
      setpriorityOrderErrorText('This is a required field');
      setTypeError(true);
      setTypeText('Please select a type');
    } else if(!icp) {
      setIcpError(true);
      setIcpText('This is a required field');
    } else if(!rule) {
      setRuleError(true);
      setRuleText('This is a required field');
    } else if(!stage) {
      setStageError(true);
      setStageText('This is a required field');
    } else if(!priorityOrder) {
      setpriorityOrderError(true)
      setpriorityOrderErrorText('This is a required field');
    } else if (!type) {
      setTypeError(true)
      setTypeText('Please select a type');
    } else {
      try {
        const response = await newIcpData({icp, stage, rule, priorityOrder, type, tenantId});
        setIcp('');
        setType('');
        setRule('');
        setStage('');
        setpriorityOrder('');
        props.renderHandler();
        setOpen(false);
      } catch (error) {
        setOpen(true);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    if(e._reactName === 'onClick') {
      setIcp('');
      setType('');
      setRule('');
      setStage('');
      setpriorityOrder('');
      setIcpText('');
      setRuleText('');
      setStageText('');
      setpriorityOrderErrorText('');
      setTypeText('');
      setIcpError(false);
      setRuleError(false);
      setStageError(false);
      setpriorityOrderError(false);
      setTypeError(false);
      setOpen(false);
    }
  };

  const handleICP = (e) => {
    if(e.target.value === ''){
      setIcpError(true);
      setIcpText('This is a required field');
    } else {
      setIcpError(false);
      setIcpText('');
    }
    setIcp(e.target.value)
  }

  const handleStage = (e) => {
    if(e.target.value === ''){
      setStageError(true);
      setStageText('This is a required field');
    } else {
      setStageError(false);
      setStageText('');
    }
    setStage(e.target.value)
  }

  const handleType = (e) => {
    if(e.target.value === ''){
      setTypeError(true);
      setTypeText('Please select a type');
    } else {
      setTypeError(false);
      setTypeText('');
    }
    setType(e.target.value)
  }

  const handlePO = (e) => {
    if(e.target.value === ''){
      setpriorityOrder(true);
      setpriorityOrderErrorText('This is a required field');
    } else {
      setpriorityOrder(false);
      setpriorityOrderErrorText('');
    }
    setpriorityOrder(e.target.value)
  }

  const handleRule = (e) => {
    if(e.target.value === ''){
      setRuleError(true);
      setRuleText('This is a required field');
    } else {
      setRuleError(false);
      setRuleText('');
    }
    setRule(e.target.value)
  }

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} style={{ fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          }}>Create New ICP Rules</Button>
      <Dialog
        open={open}
        onClose={e => handleClose(e)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Edit"
      >
        <div className={classes.dialogContent}>
        <DialogTitle id="alert-dialog-title">{"New ICP Rules"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">  
          <div>
                <FormControl  required={true} >
                <label style={{color:"#212121"}}>Priority Order</label>
                 <TextField
                    id="outlined-disabled"
                    value={priorityOrder}
                    error={priorityOrderError}
                    helperText={priorityOrderErrorText}
                    onChange={ e => handlePO(e)}
                    style={{width:300}}
                    variant="standard"
                    type="number"
                />
                </FormControl>
              </div>
          <div>
                <FormControl required={true}>
                <label style={{color:"#212121"}}>ICP</label>
                 <TextField
                    id="outlined-disabled"
                    value={icp}
                    error={icpError}
                    helperText={icpText}
                    onChange={e => handleICP(e)}
                    style={{ width: 300 }}
                    variant="standard"
                />
                </FormControl>
              </div>
              <div>
                <FormControl  required={true} >
                <label style={{color:"#212121"}}>Stage</label>
                 <TextField
                    id="outlined-disabled"
                    value={stage}
                    error={stageError}
                    helperText={stageText}
                    onChange={e => handleStage(e)}
                    style={{ width: 300 }}
                    variant="standard"
                />
                </FormControl>
              </div>
              
              <div>
                <FormControl required={true} >
                <label style={{color:"#212121"}}>Rule</label>
                 <TextField
                    id="outlined-disabled"
                    value={rule}
                    error={ruleError}
                    helperText={ruleText}
                    onChange={e => handleRule(e)}
                    style={{ width: 300 }}
                    variant="standard"
                />
                </FormControl>
              </div>

              <div>
                <FormControl required={true} >
                  <label style={{ color: "#212121" }}>Type</label>
                  <Select
                    id="outlined-disabled"
                    value={type}
                    error={typeError}
                    onChange={e => handleType(e)}
                    style={{ width: 300 }}
                    variant="standard"
                  >
                  <MenuItem value={'b2AccountStage'}>b2AccountStage</MenuItem>
                  <MenuItem value={'accountLCStage'}>accountLCStage</MenuItem>
                  <MenuItem value={'contactLCStage'}>contactLCStage</MenuItem>
                  </Select>
                  <FormHelperText error={typeError}>{typeText}</FormHelperText>
                </FormControl>
              </div>

        <DialogActions className={classes.buttonsHolder}>
          <Button className={classes.largeBtn} onClick={handleClose} variant="contained" style={{ fontSize: "12px",
                          backgroundColor: "#DDDDDD",
                          borderColor: "rgb(33, 218, 140)",
                          color: "black",
                          borderRadius: "18px",
                          marginRight: "10px",
                          padding: "8px 10px"
                      }}> Close </Button>
          <Button className={classes.largeBtn} onClick={handleSave} variant="contained" style={{ fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight: "10px",
                          padding: "8px 10px"
                      }}>Create</Button>
        </DialogActions>
          </DialogContentText>
        </DialogContent>
        </div>
      </Dialog>
</div>
  );
}
