import React from 'react';
import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TextareaAutosize } from '@material-ui/core';
import './newConfig.css';
import FormControl from '@material-ui/core/FormControl';
import { useAuthState } from '@bamboobox/b2logincheck';
import { configCreation, getConfig } from '../../../api';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
const filter = createFilterOptions();
const useStyles = makeStyles((theme) => ({
  largeBtn: {
    width: 140
  },
  dialog: {
    '&.MuiDialog-paper.MuiDialog-paperScrollPaper': {
      height: '605px'
    }
  },
  root: {
    width: '500px',
    marginLeft: 45
  },
  formControl: {
    //margin: theme.spacing(1),
    margin: '0.8rem',
    width: 200,
    marginLeft: theme.spacing(0)
  },
  textAreaH: {
    display: 'none'
  },
  textAreaV: {
    display: 'block'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paraV: {
    color: '#ff0000'
  },
  paraH: {
    display: 'none'
  }
}));

export default function NewConfig(props) {
  const classes = useStyles();
  //const userData = useAuthState();
  const [values, setValues] = useState('');
  const [open, setOpen] = React.useState(false);
  const [tools, setTools] = useState([]);
  const [selectedTool, setSelectedTool] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [keys, setKeys] = useState([]);
  const [showText, setShowText] = useState(false);
  const [showError, setShowError] = useState(false);
  const userData = useAuthState().user;
  const [msg, setMsg] = useState('');
  const tenantId = userData.tenantId;

  const handleSaveClick = async () => {
    if (!selectedKey || !values) {
      setMsg('All fields required');
      setShowError(true);
    } else {
      const data = {
        tool: selectedTool,
        configKey: selectedKey,
        configVal: values.trim(),
        tenantId: tenantId
      };
      const msg = await configCreation(data);
      setSelectedKey(null);
      setSelectedTool(null);
      setValues('');
      setOpen(false);
      setShowText(false);
      props.renderHandler();
    }
  };

  const handleClickOpen = async () => {
    //console.log(tools.length);
    if (tools.length === 0) {
      try {
        const response = await fetch(
          window._env_.TENANT_CONFIG + `/tenant/getAllTools`,
          {
            method: 'GET',
            credentials: 'include'
            //headers: { "auth-token": token },
          }
        );
        const data = await response.json();
        const tools = data.filter((obj) => obj !== null);
        //console.log('tooooooooools', tools);
        setTools(tools);
      } catch (error) {
        setTools([]);
        alert('tools fetch failed');
      }
    }
    //console.log(tools.length);
    try {
      if (keys.length === 0) {
        const response = await fetch(
          window._env_.TENANT_CONFIG + `/tenant/getAllKeys`,
          {
            method: 'GET',
            credentials: 'include'
            //headers: { "auth-token": token },
          }
        );
        const data = await response.json();
        setKeys(data);
      }
    } catch (error) {
      setKeys([]);
      alert('keys fetch failed');
    }
    setOpen(true);
  };

  const handleClose = (e) => {
    if (e._reactName === 'onClick') {
      setOpen(false);
    }
  };

  const handleTools = (newValue) => {
    // setSelectedTool(prop);
    //console.log(newValue);
    if (typeof newValue === 'string') {
      setSelectedTool(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setSelectedTool(newValue.inputValue);
    } else {
      setSelectedTool(newValue);
    }
  };

  const handleKey = (newValue) => {
    //console.log(newValue);
    if (typeof newValue === 'string') {
      setSelectedKey(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setSelectedKey(newValue.inputValue);
    } else {
      setSelectedKey(newValue);
    }
  };

  const handlechange = (prop) => {
    setValues(prop.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setShowError(false);
    if (selectedKey !== null) {
      const obj = {
        tenantId: tenantId,
        selectedTool: selectedTool,
        selectedKey: selectedKey
      };
      const response = await getConfig(obj);
      if (response.data.length === 0) {
        setShowText(true);
      } else {
        setShowText(false);
        setMsg('Selected tool and key already exists');
        setShowError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTool, selectedKey]);

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{
          fontSize: '12px',
          backgroundColor: 'rgb(33, 218, 140)',
          borderColor: 'rgb(33, 218, 140)',
          color: 'rgb(255, 255, 255)',
          borderRadius: '18px'
        }}
      >
        +New Config
      </Button>
      <Dialog
        className="newConfig"
        open={open}
        onClose={(e) => handleClose(e)}
        aria-labelledby="alert-dialog-title new"
        aria-describedby="alert-dialog-description new"
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="alert-dialog-title" style={{ marginLeft: '45px' }}>
          {'New Config'}
        </DialogTitle>
        <DialogContent className={classes.root}>
          <DialogContentText id="alert-dialog-description">
            <form>
              <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <label>Tools</label>
                  <Autocomplete
                    id="combo-box-demo"
                    value={selectedTool}
                    onChange={(e, newValue) => handleTools(newValue)}
                    options={!tools ? [{ label: 'Loading...', id: 0 }] : tools}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add ${inputValue}`
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return `Add "${option.inputValue}"`;
                      }
                      // Regular option
                      return option.title;
                    }}
                    //renderOption={(props, option) => <li {...props}>{option.title}</li>}
                    selectOnFocus
                    clearOnBlur
                    freeSolo
                    handleHomeEndKeys
                    style={{ width: 400 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Tools" variant="outlined" />
                    )}
                  />
                </FormControl>
              </div>

              <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <label>ConfigKeys</label>
                  <Autocomplete
                    id="combo-box-demo"
                    options={keys}
                    value={selectedKey}
                    onChange={(e, newValue) => handleKey(newValue)}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return `Add "${option.inputValue}"`;
                      }
                      // Regular option
                      return option.title;
                    }}
                    selectOnFocus
                    clearOnBlur
                    freeSolo
                    handleHomeEndKeys
                    style={{ width: 400 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ConfigKeys"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <div className={showText ? classes.paraV : classes.paraH}>
                  <label style={{ color: '#000000' }}>Config-Values</label>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    value={values}
                    onChange={(prop) => handlechange(prop)}
                    style={{ width: 400 }}
                  />
                </div>
                <Typography
                  className={showError ? classes.paraV : classes.paraH}
                  component={'span'}
                  variant={'body2'}
                >
                  {msg}
                </Typography>
              </FormControl>
              <DialogActions
                style={{
                  marginTop: '20px',
                  justifyContent: 'space-around',
                  width: '400px'
                }}
              >
                <Button
                  className={classes.largeBtn}
                  onClick={handleClose}
                  style={{
                    fontSize: '12px',
                    backgroundColor: '#DDDDDD',
                    borderColor: 'rgb(33, 218, 140)',
                    color: 'black',
                    borderRadius: '18px',
                    marginRight: '10px',
                    padding: '8px 10px'
                  }}
                >
                  Close
                </Button>
                <Button
                  className={classes.largeBtn}
                  onClick={handleSaveClick}
                  variant="contained"
                  style={{
                    fontSize: '12px',
                    backgroundColor: 'rgb(33, 218, 140)',
                    borderColor: 'rgb(33, 218, 140)',
                    color: 'rgb(255, 255, 255)',
                    borderRadius: '18px',
                    marginRight: '10px',
                    padding: '8px 10px'
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
