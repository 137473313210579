import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {FormControl} from '@mui/material/';
import {TextField} from "@mui/material";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { makeStyles } from '@material-ui/styles';
import EditIcon from "@material-ui/icons/Edit";

import "./editConfigJson.css"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  largeBtn: {
    padding: 10,
    width: 140,
  },
  // root : {
  //   width: "500px",
  //   marginLeft:45
  // },
  formControl: {
    //margin: theme.spacing(1),
    margin:"0.8rem",
    marginLeft: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formRoot:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-around",
    width:"100%",
    margin:"40px auto"
  },
  textRoot:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    width:"80vw",
    margin:"20px auto"
  },paraV:{
    color:"#ff0000",
    display:"block"
  },
  paraH:{
    display:"none"
  },
}));

export default function FullScreenDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [values,setValues]=useState(props.configVal);
  const [color,setColor] =useState("#000000");
  const [disable,setDisable]=useState(false);
  const [showError,setShowError]=useState(false);
  const [msg,setMsg]=useState("");
  const classes=useStyles();

  const handleSaveClick=async()=>{
    if(!values){
      setMsg("config values are required");
      setShowError(true);
    }else{
      const requestOptions = {
        method: 'POST',
        credentials:"include",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ rowId:props.rowId,configVal:JSON.parse(values) })
    };
    await fetch(window._env_.TENANT_CONFIG+'/tenant/updateTenantConfigJson', requestOptions)
    .then(response => response.json());
    props.renderHandler();
    setOpen(false);
    }
   
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlechange=(e)=>{
    //console.log(e.target.value)
    try {
            JSON.parse(e.target.value)
            setDisable(false)
            //console.log(e.target.value);
            setColor("#000000")
        } catch (error) {
            setDisable(true)
            setColor("#990000")
        }
        setValues(e.target.value)
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
            <EditIcon style={{color:"rgb(33, 218, 140)"}} />
      </IconButton>
      <Dialog
        className='full'
        sx={{height:"100vh",width:"100vw"}}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon  style={{color:"rgb(33, 218, 140)"}} />
            </IconButton>
            <Typography variant="h6" style={{fontSize: "20px",color: "rgb(89, 89, 89)",fontWeight: 700}}>Edit Config-Json</Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.formRoot}>
                <FormControl variant="outlined" className={classes.formControl} >
                 <label style={{color:"#212121"}}>Tool</label>
                 <TextField
                    disabled
                    id="outlined-disabled"
                    defaultValue={props.tool}
                    style={{width:300}}
                />  
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <label style={{color:"#212121"}}>ConfigKey</label>
                    <TextField
                        disabled
                        id="outlined-disabled"
                        defaultValue={props.configKey}
                        style={{width:300}}
                    />
                </FormControl>
        </div>
        <div className={classes.textRoot}>
              <FormControl variant="outlined" >
                   
                   <label style={{color:"#212121"}}>Config-Values</label>
                    <TextareaAutosize 
                    aria-label="minimum height" 
                    minRows={7}
                    value={values} 
                    onChange={(e)=>handlechange(e)} 
                    className="textarea"
                    style={{ padding:"20px 40px", borderColor:"rgba(118, 118, 118, 0.3)", color:`${color}` }}
                    />
              </FormControl>
              <Typography className={showError?classes.paraV:classes.paraH} component={'span'} variant={'body2'}>{msg}</Typography>
        </div>
        <Button
                  onClick={handleSaveClick}
                  className={classes.largeBtn}
                  variant="contained"
                  type="button"
                  disabled={disable}
                  style={{  fontSize: "15px",
                  backgroundColor: "rgb(33, 218, 140)",
                  borderColor: "rgb(33, 218, 140)",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "18px",
                  margin:"20px auto"
                  }} 
                >
                  Save
            </Button>
      </Dialog>
    </div>
  );
}
