import React from 'react';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './NewUser.css';
import { makeStyles } from '@material-ui/styles';
import Axios from 'axios';
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import token from '../token';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { borderRadius } from '@mui/system';
import { updateUser } from '../api';
import Autocomplete from '@mui/material/Autocomplete';
// import isEmail from 'validator/lib/isEmail';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles(theme => ({
  form:{
    display:"flex",
    flexDirection:"column",
    marginLeft:"20px"
  },
  largeBtn: {
      width: 120
  },
  formControl: {
    margin: theme.spacing(1),
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginLeft: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  superUser:{
    display:"flex",
    alignItems:"center",
    marginBottom:"10px"
  },
  buttonsHolder:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginTop:"20px"
  },
  col:{
    marginBottom:"10px"
  },
  dialogContent:{
    margin: "auto",
    marginTop: "15px",
  },
 }));

const EditUser = (props)=> {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [department, setDepartment] = useState(props.department);
  const [nameError,setNameError]=useState(false);
  const [nameText,setNameText]=useState("");
  const first=props.name.split(" ");
  const [firstName, setFirstName] = useState(first[0]);
  const [lastName, setLastName] = useState(first[1]);
  const [superUser,setSuperUser]=useState(props.superUser);
  const [selectedRoles,setSelectedRoles]=useState(props.selectedRoles);
  const [selectedDag,setSelectedDag]=useState(props.selectedDag[0] || null);
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleFirstName=(e)=>{
    if(e.target.value.length>128){
      setNameError(true);
      setNameText("required")
      return;
    }
    if(e.target.value===""){
      setNameError(true);
      setNameText("required")
    }else{
      setNameError(false);
      setNameText("");
    }
    setFirstName(e.target.value)
  }

  const handleSave=async()=>{
    const rolesToUpdate=selectedRoles.filter((obj)=> obj?.roleId !== "0000");
    if(!firstName){
      setNameError(true);
      setNameText("required")
    }else{
      let dagToUpdate;
      if(!selectedDag || selectedDag===null){
        dagToUpdate=[]
      }else{
        dagToUpdate=[selectedDag]
      }
    const data={
      "userId":props.userId,
      "firstName":firstName,
      "lastName":lastName || "",
      "department":department,
      "superUser":superUser,
      "roles":rolesToUpdate,
      "dags":dagToUpdate
    }
    try {
      const response=await updateUser(data);
      props.renderHandler();
      props.setMessage("Updated Successfully");
      props.setOpen(true);
      
    } catch (error) {
      console.log("error",error);
      props.setMessage("Unable to create try again");
      props.setOpen(true);
    }
  }
}

  const handleClose = (e) => {
    if(e._reactName === "onClick"){
      setOpen(false);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleRoleChange=(prop)=>{
    const newData=prop.filter((obj)=>obj.roleId !== "0000");
    console.log("new data",newData);
    setSelectedRoles(newData);
  }

  const handleDagChange=(prop)=>{
    // const newData=prop.filter((obj)=>obj.roleId !== "0000");
    setSelectedDag(prop);
  }

  const optionDisabledRole=(option)=>{
    const names=selectedRoles.map((row)=>{return row.roleName});
    return names.includes(option.roleName)
  }


  return (
    <div>
      <IconButton onClick={handleClickOpen}>
          <EditIcon style={{color:"rgb(33, 218, 140)"}} />
      </IconButton>
      <Dialog
        open={open}
        onClose={e=>handleClose(e)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Edit"
      >
        <div className={classes.dialogContent}>
        <DialogTitle id="alert-dialog-title">{"Edit User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">  
          <div>
                <FormControl >
                <label style={{color:"#212121"}}>FirstName</label>
                 <TextField
                    id="outlined-disabled"
                    value={firstName}
                    error={nameError}
                    helperText={nameText}
                    onChange={e=>handleFirstName(e)}
                    style={{width:300}}
                    variant="standard"
                />
                </FormControl>
              </div>
              <div>
                <FormControl  >
                <label style={{color:"#212121"}}>LastName</label>
                 <TextField
                    id="outlined-disabled"
                    value={lastName}
                    onChange={e=>setLastName(e.target.value)}
                    style={{width:300}}
                    variant="standard"
                />
                </FormControl>
              </div>
              <div>
                <FormControl required={true} >
                <label style={{color:"#212121"}}>Email</label>
                 <TextField
                    id="outlined-disabled"
                    disabled
                    defaultValue={props.email}
                    autoComplete="email"
                    style={{width:300}}
                    variant="standard"
                />
                </FormControl>
              </div>
              <FormControl variant="outlined" >
          <div>
            <label style={{color:"#212121"}}>Roles</label>
            <Autocomplete
                multiple
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                onChange={(e,newValue)=>handleRoleChange(newValue)}
                id="multiple-limit-tags"
                options={props.roles}
                getOptionLabel={(option) => option.roleName}
                value={selectedRoles}
                getOptionDisabled={(option)=>optionDisabledRole(option)}
                renderInput={(params) => (
                <TextField {...params} label="Select Role" variant="outlined"/>
                )}
                style={{width:400}}
            />
          </div>
        </FormControl>
        <FormControl variant="outlined" >
          <div>
            <label style={{color:"#212121"}}>DAG</label>
            <Autocomplete
                freeSolo
                onChange={(e,newValue)=>handleDagChange(newValue)}
                id="single-limit-tags"
                options={props.dag}
                getOptionLabel={(option) => option.dagName}
                value={selectedDag}
                renderInput={(params) => (
                <TextField {...params} label="Select DAG" variant="outlined"/>
                )}
                style={{width:400}}
            />
          </div>
        </FormControl>

        <DialogActions className={classes.buttonsHolder}>
          <Button className={classes.largeBtn} onClick={handleClose}  variant="contained"  style={{  fontSize: "12px",
                          backgroundColor: "#DDDDDD",
                          borderColor: "rgb(33, 218, 140)",
                          color: "black",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}>
            Close
          </Button>
          <Button className={classes.largeBtn} onClick={handleSave} variant="contained" style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}>
            Save
          </Button>
        </DialogActions>
          </DialogContentText>
        </DialogContent>
        </div>
      </Dialog>
</div>
  );
}

export default EditUser;