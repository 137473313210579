import React from "react";
import { Button } from '@mui/material'
import { useAuthState } from '@bamboobox/b2logincheck';

export default function DownloadAttributeData(props) {
const logindata=useAuthState();
const Id=logindata.user.tenantId;
const handleTemplateDownload = () => {
    let data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(props.data));
    let downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", data);
    downloadAnchorNode.setAttribute("download", props.type+Id+".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
}
return (
        <Button variant="contained" onClick={handleTemplateDownload} style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          }} > Download Attribute Data </Button>
    )
}     