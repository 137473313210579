import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Typography } from '@material-ui/core';
import Roles from './Roles';
import NewRole from './NewRole';
import RolesV1 from './RolesV1';


const useStyles = makeStyles({
    container:{ 
      width: "100%", 
      marginTop:"120px" ,
      boxShadow:"rgb(0 0 0 / 10%) 0px 0px 16px", 
      background: "rgb(255, 255, 255)",
      borderRadius:"15px",
      marginLeft:"20px"
    },
});

function RoleManagement(props){
    const classes = useStyles();
    return(
        <>
        <Container className={classes.container}>
            <RolesV1 handleMainRender={props.handleMainRender}/>
        </Container>
        
        </>
    );
}

export default RoleManagement;