import React from 'react';
import {useDropzone} from 'react-dropzone';
import { useEffect, useState, useCallback } from 'react';
import "./MObjectStart.css";
import papa from 'papaparse'

function DropZone(props) {

    // const getFilesFromEvent = (event) => {
  //   console.log("event coming from basic.js", event)
  //   props.setSelectedFileData({ selectedFile: event?.target?.files[0] });
  //   props.setSelectFileName(event?.target?.files[0]?.name);
  // }
  
  const onDrop = useCallback((acceptedFiles) => {
    
    console.log("coming from basic.js ondrop", acceptedFiles)
    
    
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')

      //this is fired when reader.readAsText is completed
      reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result
        // console.log( "coming from onDrop", binaryStr)

        let parsedStr = papa.parse(binaryStr);
        console.log("paresedStr", parsedStr?.data);
        let headers = parsedStr?.data[0];
        let dataArray = [];
        for(let i = 1; i < parsedStr?.data?.length ; i++){
            let dataObj = {};
            for(let j=0; j < headers?.length ; j++){
                dataObj[headers[j]] = parsedStr?.data[i][j];
            }
            dataArray.push(dataObj);
            //console.log("dddd",dataObj);
        }
        props.setSelectedFileData(dataArray);
      }
      reader.readAsText(file)
      // console.log("value of reader", reader)
    })
  }, [])

  
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({    
    maxFiles:1, onDrop
  });

  
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));



  return (
    <section className="dropzone_container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p style={{fontWeight: "700", fontSize: "16px", color: "#184D27"}}><span style={{textDecoration: "underline"}}>Browse</span></p>
        <p style={{fontWeight: "500", fontSize: "16px",color: "#030303", opacity: "0.5" }}>Make sure the file is in csv format</p>
      </div>
      <aside>
        {/* <h4>Files</h4> */}
        <ul>{files}</ul>
      </aside>
    </section>
  );
}

export default DropZone