/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {Container, TextareaAutosize} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import EditResources from './EditResources';
import NewRole from './NewRole';
import Typography from '@material-ui/core/Typography';
import { roleDeletion } from '../../api';
import {Dialog,DialogContent} from '@mui/material';
import ConfigExport from '../../tenantConfig/tenantConfigv1/ConfigExport';
import BulkScreens from './BulkScreens'
import "./rolesV1.css";
import { useAuthState } from "@bamboobox/b2logincheck";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    fontWeight: 'bold',
    paddingBottom:"20px",
    height:"100%",
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: "#00a75e"
  }},
  bord: {
    padding: 20,
    //border: '0.2px solid grey',
    height: '90%'

},
  '& .MuiCheckbox-colorSecondary.Mui-checked':{
    color: '#6b778c'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "25%",
  },
  tab:{
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#109CF1"
  },
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
    },
    "& .MuiTab-wrapper>span": {
        textDecoration: "uppercase",
    },
    "& .MuiTab-wrapper>span>span": {
      display: "none",
    },
    "&:hover .MuiTab-wrapper>span>span": {
      float : 'right',
      marginLeft: 40,
      display: "inline",
    }},
    headContainer: {
      display:"flex",
      justifyContent:"space-between",
      margin:"20px 20px 0px 20px",
      paddingTop:"10px",
      paddingBottom:"10px"
    }

  }
));

export default function RolesV1(props) {
  const classes = useStyles();
  const logindata = useAuthState();
  const [value, setValue] = useState(0);
  const [roles, setRoles] = useState([]);
  const [render,setRender] = useState(false);
  const [resources,setResources] =useState([])
  //const [allResources,setAllResources]=useState([]);
  const [open,setOpen]=useState(false)
  const [message,setMessage]=useState("")
  const [selectedResources,setSelectedResources]=useState([]);
  const [showBulk,setShowBulk]=useState(false);
  const roletoresourceUrl = window._env_.LOGIN_API_URL+"/roletoresourceoperation/getallroletoresourceoperation";
  console.log("roletoresources..............",roletoresourceUrl)

  const renderHandler=()=>{
    setRender(!render)
  }
  const handleClose=()=>{
    setOpen(false);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedResources(roles[newValue].resources)
  };
  const handleDelete=async()=>{
    console.log(roles[value]);
    try {
      const response=await roleDeletion(roles[value])
      setValue(0);
      renderHandler();
      //props.handleMainRender();
    } catch (error) {
      console.log(error);
    }
    
  }
  
    useEffect(async()=>{
      setSelectedResources([])
      try {
        const response=await fetch(roletoresourceUrl,
          {
            method: "GET",
            credentials: "include",
          })
          const data=await response.json();
          console.log("role to resources...../.......",data)
          let dummyDatalog=logindata?.permissions?.filter((obj)=>obj.resourceName==='tenant_creation');
          console.log("-------------------------",dummyDatalog);
          if(dummyDatalog.length){
            setRoles(data);
            setSelectedResources(data[value].resources)
          }else{
            let dataaa=data.filter((obj)=>obj.roleName!=='Tenant Creator')
            setRoles(dataaa);
            setSelectedResources(dataaa[value].resources)
          }
          
      } catch (error) {
        console.log(error);
        setMessage("Please refresh and  try again")
        setOpen(true)
      }
  },[render]);

  useEffect(async()=> {
    try {
      const response=await fetch(window._env_.LOGIN_API_URL+`/resourceoperation/getallresourceoperation`,
      {
        method: "GET",
        credentials: "include",
      })
    const data=await response.json();
    console.log("check.........././././../..",data)
    let dummyDatalog=logindata?.permissions?.filter((obj)=>obj.resourceName==='tenant_creation');
    console.log("----------------ooo---------",dummyDatalog);
    if(dummyDatalog.length){
      if(data){
        setResources(data);
      }else{
        setResources([])
      }
    }
    else{
      let dataaa=data.filter((obj)=>obj.resourceName!=='tenant_creation')
      if(dataaa){
        setResources(dataaa)
      }else{
        setResources([])
      }
    }
      
    
    // else{
    //   let dataaa=data.filter((obj)=>obj.roleName!=='Tenant Creater')
    //   setResources(dataaa)
    //   console.log("kkkkkkkkkkk.....kkkkkkkkkkkk",dataaa)
    // }
    
    // if(data){
    //   setResources(data);
    //   //console.log("check",data)
    //   //setAllResources(data);
    // }else{
    //   setResources([])
    // }
    } catch (error) {
      console.log(error);
      setResources([])
    }
    
  },[render])

//   const optionDisabled=(option)=>{
//     const names=selectedResources.map((row)=>{return row.roleName});
//     return names.includes(option.roleName)
//   }

  return (
    <>
    {showBulk?<Container className={classes.smallCont}>
    <BulkScreens renderHandler={renderHandler} setShowBulk={setShowBulk}/>
</Container>:
<>
 <div className={classes.headContainer}>
 <Dialog
             className="msg"
             onClose={e=>handleClose(e)}
             open={open}
             aria-labelledby="alert-dialog-title edit"
             aria-describedby="alert-dialog-description edit"
             id="custom message"
             >
            <DialogContent style={{margin:"auto",display:"flex"}}>
             <Typography variant="h6">{message}</Typography>
            </DialogContent>
             </Dialog>
 <Typography variant="h6" style={{fontSize: "20px",color: "rgb(89, 89, 89)",fontWeight: 700}}>Role Management</Typography>
 <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
 <Button variant="contained" color="primary"  style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"20px",
                          }} onClick={()=>setShowBulk(true)} >Bulk Roles Creation</Button>
 <ConfigExport data={roles} type={"Roles"} title={"Download Roles"} /> 
 <NewRole setMessage={setMessage} setOpen={setOpen}  renderHandler={renderHandler}/>
 </div>
 </div>
 <Container className={classes.bord}>
   <div className={classes.root}>
   <Tabs
     orientation="vertical"
     variant="scrollable"
     //indicatorColor="primary"
     //textColor="primary"
     value={value}
     onChange={handleChange}
     aria-label="Vertical tabs example"
     className="roles"
     style={{marginLeft:"20px"}}
   >
     { roles.map((role,ind)=>{
       return (
       <Tab className={classes.tab} label={<span style={{display:"flex",justifyContent:"space-between"}}>{role.roleName}
         {/* <DeleteIcon onClick={(e) => {handleDeleteClick(role.roleId)}} abhi="12ka4"/> */}
       </span>} {...a11yProps(ind)} />
       );
     })}
   </Tabs>
   <div style={{padding:"10px",margin:"15px"}}>
     <Autocomplete
         multiple
         freeSolo
         selectOnFocus
         clearOnBlur
         handleHomeEndKeys
         //limitTags={4}
         readOnly
         //onChange={(e,newValue)=>handleChange(newValue)}
         //filterOptions={(x) => x}
         id="multiple-limit-tags"
         options={!resources ? [{label:"Loading...", id:0}] :resources}
         getOptionLabel={(option) => option.resourceName+" : "+option.operationName}
         value={selectedResources}
         //getOptionDisabled={(option)=>optionDisabled(option)}
         renderInput={(params) => (
         <TextField {...params} variant="outlined" label="Resources"
     />
     )}
     sx={{width:600}}
   /> 
   </div> 
   <div style={{display:"flex",justifyContent:"center",alignItems:"flex-start",marginTop:"20px"}}>
     <IconButton >
         <DeleteIcon onClick={handleDelete}  style={{color:"rgb(33, 218, 140)"}} /> 
     </IconButton>
     <EditResources data={roles[value]} setMessage={setMessage} setOpen={setOpen} resources={resources} renderHandler={renderHandler}/>
   </div>
 </div> 
 </Container></>}
    </>
  );
}
