import React, {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/styles';
import { useAuthState } from '@bamboobox/b2logincheck';
import { Button } from '@mui/material';
import { Container, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { getAllIcpData } from '../api';
import EditIcp from './EditIcpRules';
import NewIcpRules from "./NewIcpRules";
import DeleteIcpRules from "./DeleteIcpRules";
import DownloadIcpRules from './DownloadIcpRules';
import ExportIcpRules from './HandleBulkIcpRules';

const useStyles = makeStyles((theme) => ({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1em',
      alignItems: 'center'
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    smallCont: {
      height: 450,
      width: '100%',
      margin: '0 1em 1em'
    },
  }));

  // function MyExportButton() {
  //   return (
  //     <GridToolbarContainer>
  //       <GridToolbarExport />
  //     </GridToolbarContainer>
  //   );
  // }

const ICP = () => {
    const [rows, setRows] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [render, setRender] = useState(false);
    const [showBulk, setShowBulk] = useState(false);
    const userData = useAuthState().user;
    const tenantId = userData.tenantId;
    const classes = useStyles();

    const columns = [
      {
          field: 'priority_order', headerName: 'Priority Order', sortable: false, width: 150
      },{
          field: 'icp', headerName: 'ICP', sortable: false, width: 100
      }, {
          field: 'stage', headerName: 'Stage', sortable: false, width: 170
      },{
          field: 'rule', headerName: 'Rule', sortable: false, width: 780
      },{
        field: 'type', headerName: 'Type', sortable: false, width: 140
      },{
          field: 'actions', headerName: 'Actions', sortable: false, width: 114,
          renderCell: (params) => {
            return (
              <>
                <EditIcp 
                  renderHandler = {renderHandler}
                  rowId = {params.row.rowId}
                  icp = {params.row.icp}
                  stage = {params.row.stage}
                  rule = {params.row.rule}
                  priority_order = {params.row.priority_order}
                  tenantId = {params.row.tenantId}
                  type = {params.row.type}
                />
                <DeleteIcpRules
                  renderHandler = {renderHandler}
                  rowId = {params.row.rowId}
                  tenantId = {params.row.tenantId}
                />  
              </>
            )
          }
      }
    ]
    
    function renderHandler() {
      setRender(!render);
    }  

    useEffect(() => {
      const fetchData = async () => {
        setIsloading(true);
        try {
          const response = await getAllIcpData(tenantId);
          const data = response.data.data;
          setRows(data);
          setIsloading(false);
        } catch (err) {
          console.log(err);
        }
      }
      fetchData();
    }, [tenantId, render])

    return (
        <>
            {showBulk ? (
              <Container className={classes.smallCont}>
              <ExportIcpRules renderHandler={renderHandler} setShowBulk={setShowBulk} />
              </Container>
            ) : (
            <Container>
            <div className={classes.header} style={{ margin: '10px 10px' }}>
            <Typography variant="h6" style={{
                overflowY: 'hidden',
                fontSize: '20px',
                color: 'rgb(89, 89, 89)',
                fontWeight: 700
              }}> ICP Rules </Typography>
            <div className={classes.buttons}>            
            <NewIcpRules renderHandler={renderHandler} variant="contained" color="primary" style={{
                  fontSize: '12px',
                  backgroundColor: 'rgb(33, 218, 140)',
                  borderColor: 'rgb(33, 218, 140)',
                  color: 'rgb(255, 255, 255)',
                  borderRadius: '18px',
                  // marginRight: '20px'
                }}> Create New ICP Rules </NewIcpRules> 
            <Button
                variant="contained"
                color="primary"
                style={{
                  fontSize: '12px',
                  backgroundColor: 'rgb(33, 218, 140)',
                  borderColor: 'rgb(33, 218, 140)',
                  color: 'rgb(255, 255, 255)',
                  borderRadius: '18px',
                  marginRight: '20px',
                  marginLeft: '20px'
                }}
                onClick={() => setShowBulk(true)}
              > Bulk ICP Rules Upload </Button>    
            <DownloadIcpRules data={rows} type={'JSON'}/>   
            </div>  
            </div>
            <Container style={{height:"640px",boxShadow:"rgb(0 0 0 / 10%) 0px 0px 16px", background: "rgb(255, 255, 255)",borderRadius:"15px" }}>
                <DataGrid getRowId={(row) => row.rowId} rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} disableSelectionOnClick/>
            </Container>
            </Container>   
          )}           
        </>
    )
}   

export default ICP;