import React, { useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
//import TextField from '@material-ui/core/TextField';
import TextField from '@mui/material/TextField';
import token from '../../token';
import {useAuthState} from "@bamboobox/b2logincheck";


// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: 500,
//     flex:1
//   },
// }));

export default function AutoCompleteReadOnlyChip(props) {

  const field=props.fieldName;
  return (
    <div >
      <Autocomplete
        multiple
        freeSolo
        id="tags-readOnly"
        readOnly
        limitTags={1}
        options={props.roles.map((obj)=>obj[field])}
        defaultValue={props.roles.map((obj)=>obj[field])}
        renderInput={(params) => (
          <TextField {...params} variant="outlined"/>
        )}
        sx={{width:350}}
      />
    </div>
  );
}