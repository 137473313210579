import axios from 'axios';

export const userCreation = async (userdetails) => {
  const url = window._env_.LOGIN_API_URL + '/users/create-user';
  try {
    const response = await axios.post(
      url,
      {
        department: userdetails.department ? userdetails.department : '',
        email: userdetails.email.trim(),
        name: `${userdetails.firstName.trim()} ${userdetails.lastName.trim()}`,
        password: 'test123',
        superUser: userdetails.superUser ? userdetails.superUser : false,
        roles:userdetails?.selectedRoles,
        dags:(userdetails?.selectedDag && userdetails?.selectedDag!==null)?[userdetails.selectedDag]:[]
      },
      {
        withCredentials: true
        //headers: { "auth-token": token },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
    //alert("user creation failed due to internal server error please try again");
  }
};

export const bulkUserCreation = async (props) => {
  const url = window._env_.LOGIN_API_URL + '/users/bulkUserCreation';
  const userdata = [];
  for (const obj of props) {
    if (obj.firstName && obj.lastName && obj.email) {
      userdata.push({
        department: obj.department ? obj.department : '',
        email: obj.email.trim(),
        name: `${obj.firstName.trim()} ${obj.lastName.trim()}`,
        password: 'test123',
        superUser: obj.superUser ? obj.superUser : false
      });
    }
  }
  try {
    const response = await axios.post(
      url,
      {
        userData: userdata
      },
      {
        withCredentials: true
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
    //alert("bulk user creation failed due to internal server error please try again");
  }
};

export const updateUser = async (props) => {
  console.log("props of update",props);
  const url = window._env_.LOGIN_API_URL + '/users/updateUser';
  try {
    const response = await axios.post(
      url,
      {
        userId: props?.userId,
        name: `${props?.firstName?.trim()} ${props?.lastName?.trim()}`,
        department: props?.department,
        superUser: props?.superUser,
        roles:props?.roles,
        dags:props?.dags
      },
      {
        withCredentials: true
        //headers: { "auth-token": token },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
    //alert("user Update failed due to internal server error please try again");
  }
};

export const roleCreation = async (props) => {
  const addURL = window._env_.LOGIN_API_URL + '/roles/addroles';
  try {
    const response = await axios.post(
      addURL,
      {
        roleName: props.roleName.trim(),
        roleDescription: props.roleDescription.trim(),
        resources: props.resources
      },
      {
        withCredentials: true
        //headers: { "auth-token": token },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const bulkRoleCreation = async (data) => {
  const promiseArray = [];
  const addURL = window._env_.LOGIN_API_URL + '/roles/addroles';
  const getAllResourcesUrl =
    window._env_.LOGIN_API_URL + '/resourceoperation/getallresourceoperation';
  const Allresources = [];
  data.map((obj) => {
    Allresources.push(...obj.resources);
  });
  console.log('resources from uploaded file', Allresources);
  const resourceUrl =
    window._env_.LOGIN_API_URL + '/resourceoperation/bulk-create-resources';
  try {
    const resourceCreation = await axios.post(resourceUrl, Allresources, {
      withCredentials: true
    });
    const getAllResourcesAfterCreating = await axios.get(getAllResourcesUrl, {
      withCredentials: true
    });
    console.log('allResources from db', getAllResourcesAfterCreating);
    const UpdatedAllResourcesMap = new Map();
    getAllResourcesAfterCreating?.data?.map((obj) => {
      UpdatedAllResourcesMap.set(obj.resourceName.trim() + obj.operationName.trim(), obj);
    });
    console.log('UpdatedAllResources', UpdatedAllResourcesMap);
    for (const obj of data) {
      const resources = [];
      obj.resources.map((x) => {
        resources.push(
          UpdatedAllResourcesMap.get(x.resourceName.trim() + x.operationName.trim())
        );
      });
      const des = !obj.roleDescription ? 'None' : obj.roleDescription;
      try {
        const response = axios.post(
          addURL,
          {
            roleName: obj.roleName,
            roleDescription: des,
            resources: resources
          },
          {
            withCredentials: true
          }
        );
        promiseArray.push(response);
      } catch (error) {
        console.log(error);
      }
    }
  } catch (error) {
    console.log(error);
  }
  const promise = await Promise.allSettled(promiseArray);
  console.log(promise);
  return promise;
};

export const roleDeletion = async (props) => {
  const addURL = window._env_.LOGIN_API_URL + '/roles/deleterole';
  try {
    const response = await axios.post(
      addURL,
      {
        roleId: props.roleId
      },
      {
        withCredentials: true
        //headers: { "auth-token": token },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

//tobecompleted
export function resourceCreation({ tenantId, resourceName, operationName }) {
  const addURL =
    window._env_.LOGIN_API_URL + 'resourceoperation/addresourceoperation';
  axios.post(
    addURL,
    {
      tenantId: tenantId,
      resourceName: resourceName.trim(),
      operationName: operationName.trim()
    },
    {
      method: 'POST',
      withCredentials: true
      //headers: { "auth-token": token },
    }
  );
}

export const updateRoleToResources = async (props) => {
  const addURL =
    window._env_.LOGIN_API_URL +
    '/roletoresourceoperation/updateRoleToResourceOperations';
  try {
    const response = await axios.post(
      addURL,
      {
        roleId: props.roleId,
        roleName: props.roleName.trim(),
        resources: props.resources
      },
      {
        withCredentials: true
        //headers: { "auth-token": token },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export function roleToResourceCreationBulk({
  tenantId,
  roleName,
  resourceOperationName,
  operationName
}) {
  const addURL =
    window._env_.LOGIN_API_URL +
    '/roletoresourceoperation/addroletoresourceoperationbulk';
  axios.post(
    addURL,
    {
      tenantId: tenantId,
      roleName: roleName.trim(),
      resourceOperationName: resourceOperationName.trim(),
      operationName: operationName.trim()
    },
    {
      method: 'POST',
      withCredentials: true
      //headers: { "auth-token": token },
    }
  );
}

export const userToRoleCreation = async (props) => {
  console.log(props);
  try {
    const addURL = window._env_.LOGIN_API_URL + '/userroles/adduserroles';
    const response = await axios.post(
      addURL,
      {
        roles: props.roles,
        userId: props.userId
      },
      {
        withCredentials: true
        //headers: { "auth-token": token },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export function userToRoleCreationBulk({ tenantId, roleName, email }) {
  const addURL = window._env_.LOGIN_API_URL + 'userroles/adduserrolebulk';
  axios.post(
    addURL,
    {
      tenantId: tenantId,
      roleName: roleName.trim(),
      email: email.trim()
    },
    {
      method: 'POST',
      withCredentials: true
      //headers: { "auth-token": token },
    }
  );
}

export function configCreation({ tenantId, tool, configKey, configVal }) {
  const url = window._env_.TENANT_CONFIG + '/tenant/set-tenant-config';
  const response = axios.post(
    url,
    {
      tenantId: tenantId,
      tool: tool,
      configArray: [
        {
          tenantConfigKey: configKey,
          tenantConfigValue: configVal
        }
      ]
    },
    {
      method: 'POST',
      withCredentials: true
    }
  );
  return response;
}

export function configCreationJson({ tenantId, tool, configKey, configVal }) {
  const url = window._env_.TENANT_CONFIG + '/tenant/set-tenant-config-json';
  const response = axios.post(
    url,
    {
      tenantId: tenantId,
      tool: tool,
      configArray: [
        {
          tenantConfigKey: configKey,
          tenantConfigValue: configVal
        }
      ]
    },
    {
      method: 'POST',
      withCredentials: true
    }
  );
  return response;
}

export const getConfig = async ({ tenantId, selectedTool, selectedKey }) => {
  const url = window._env_.TENANT_CONFIG + '/tenant/get-tenant-config';
  const response = await axios.post(
    url,
    {
      tenantId: tenantId,
      tool: selectedTool,
      keys: [selectedKey]
    },
    {
      withCredentials: true
    }
  );
  return response;
};

export const getConfigJson = async ({
  tenantId,
  selectedTool,
  selectedKey
}) => {
  const url = window._env_.TENANT_CONFIG + '/tenant/get-tenant-config-json';
  const response = await axios.post(
    url,
    {
      tenantId: tenantId,
      tool: selectedTool,
      keys: [selectedKey]
    },
    {
      withCredentials: true
    }
  );
  return response;
};

export const deleteConfig = async (data) => {
  try {
    const rowIdArray = [];
    data.map((row) => {
      rowIdArray.push(row.rowId);
    });
    const url = window._env_.TENANT_CONFIG + '/tenant/deleteTenantConfig';
    const response = await axios.post(
      url,
      {
        rowIdArray
      },
      {
        withCredentials: true
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    alert('Not able to delete');
  }
};

export const deleteConfigJson = async (data) => {
  try {
    const rowIdArray = [];
    data.map((row) => {
      rowIdArray.push(row.rowId);
    });
    const url = window._env_.TENANT_CONFIG + '/tenant/deleteTenantConfigJson';
    const response = await axios.post(
      url,
      {
        rowIdArray
      },
      {
        withCredentials: true
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    alert('Not able to delete');
  }
};

export const getAllTenantConfig = async () => {
  try {
    const response = await fetch(
      window._env_.TENANT_CONFIG + `/tenant/getAllTenantConfig`,
      {
        method: 'GET',
        credentials: 'include'
      }
    );
    const data = response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getAllTenantConfigJson = async () => {
  try {
    const response = await fetch(
      window._env_.TENANT_CONFIG + `/tenant/getAllTenantConfigJson`,
      {
        method: 'GET',
        credentials: 'include'
      }
    );
    const data = response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const updateTenantConfig = async (params) => {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ rowId: params.id, configVal: params.value.trim() })
  };
  try {
    const response = await fetch(
      window._env_.TENANT_CONFIG + '/tenant/updateTenantConfig',
      requestOptions
    );
    //const data= await response.json()
    return response;
  } catch (error) {
    console.log(error);
    alert('edit failed due to internal server error please try again');
  }
};

export const bulkCreateConfig = async (data) => {
  const jsonData = [];
  for (const obj of data) {
    console.log('type', obj.configVal, typeof obj.configVal);
    if (obj.configKey && obj.configVal) {
      if (typeof obj.configVal === 'object') {
        obj.configVal = JSON.stringify(obj.configVal);
      }
      jsonData.push(obj);
    }
  }
  try {
    const url = window._env_.TENANT_CONFIG + '/tenant/bulkUploadConfig';
    const response = await axios.post(url, jsonData, {
      withCredentials: true
    });
    return response;
  } catch (error) {
    console.log(error);
    alert('bulk upload failed due to internal server error please try again');
  }
};

export const bulkCreateConfigJson = async (data) => {
  console.log('props', data);
  const jsonData = [];
  for (const obj of data) {
    console.log('type', obj.configVal, typeof obj.configVal);
    if (obj.configKey && typeof obj.configVal === 'object') {
      jsonData.push(obj);
    }
    try {
      JSON.parse(obj.configVal);
      if (obj.configKey && typeof JSON.parse(obj.configVal) === 'object') {
        jsonData.push(obj);
      }
    } catch (error) {
      console.log('unable to parse');
    }
  }
  try {
    const url = window._env_.TENANT_CONFIG + '/tenant/bulkUploadConfigJson';
    const response = await axios.post(url, jsonData, {
      withCredentials: true
    });
    return response;
  } catch (error) {
    console.log(error);
    alert('bulk upload failed due to internal server error please try again');
  }
};

export const bulkCreateScreens = async (data) => {
  const promiseArray = [];
  for (const obj of data) {
    const tenantId = obj.tenant_id;
    const screen = obj.screen;
    const url =
      window._env_.TENANT_CONFIG +
      `/tenant/field/mapper/set/${tenantId}/${screen}`;
    try {
      const response = axios.post(url, obj.mapper_json, {
        withCredentials: true
      });
      promiseArray.push(response);
    } catch (error) {
      console.log(error);
    }
  }
  const promise = await Promise.allSettled(promiseArray);
  console.log(promise);
  return promise;
};

export const getAllActionData = async () => {
  try {
    const url = window._env_.ACTIVATION_SERVICE + '/activator/get-all-actions';
    const response = await axios.get(url, { withCredentials: true });
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const editActions = async (data) => {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data })
  };
  try {
    const url = window._env_.ACTIVATION_SERVICE + '/activator/edit-actions';
    const response = await fetch(url, requestOptions);
    //const data= await response.json()
    return response;
  } catch (error) {
    console.log(error);
    alert('edit failed due to internal server error please try again');
  }
};

export const newActions = async (data) => {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data })
  };
  try {
    const url = window._env_.ACTIVATION_SERVICE + '/activator/new-action';
    const response = await fetch(url, requestOptions);
    //const data= await response.json()
    return response;
  } catch (error) {
    console.log(error);
    alert('edit failed due to internal server error please try again');
  }
};

export const deleteActions = async (data) => {
  try {
    const serviceIdArray = [];
    data.map((row) => {
      serviceIdArray.push(row.id);
    });
    const url = window._env_.ACTIVATION_SERVICE + '/activator/delete-actions';
    const response = await axios.post(
      url,
      {
        serviceIdArray
      },
      {
        withCredentials: true
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    alert('Not able to delete');
  }
};

export const bulkCreateActions = async (dataset) => {
  const promiseArray = [];
  const url = window._env_.ACTIVATION_SERVICE + '/activator/new-action';
  for (const obj of dataset) {
    let details = obj.actionDetails;
    try {
      details = JSON.parse(obj.actionDetails);
    } catch (error) {}
    if (typeof details === 'object') {
      const data = {
        actionName: obj.actionName,
        actionLabel: obj.actionLabel,
        actionDetails: details,
        serviceName: obj.serviceName,
        serviceBaseUrl: obj.serviceBaseurl,
        publicVisibilty: obj.publicVisibilty,
        supportedType: obj.supportedType
      };
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data })
      };
      const response = fetch(url, requestOptions);
      promiseArray.push(response);
    }
  }
  const promise = await Promise.allSettled(promiseArray);
  console.log(promise);
  return promise;
};

export const getAllIcpData = async (tenantId) => {
  try {
    const url = window._env_.CDP_URL+ `/icp/getIcpRules?tenantId=${tenantId}&type=all`;
    const response = await axios.get(url, { withCredentials: true });
    return response;
  } catch (err) {
    console.log(err);
    return ;
  }
}

export const newIcpData = async(icpData) => {
  try {
    const url = window._env_.CDP_URL + '/icp/newIcpRules'
    const response = await axios.post(url, {
      icp: icpData.icp, 
      stage: icpData.stage,
      rule: icpData.rule,
      priority_order: icpData.priorityOrder,
      type: icpData.type,
      tenantId: icpData.tenantId,
    }, { withCredentials: true});
    return response;
  } catch (err) {
    console.log(err);
    return ;
  }
}

export const deleteIcpData = async(data) => {
  try {
    const rowData = data[0].rowId;
    const tenantData = data[0].tenantId;
    const IdArray = [];
    const url = window._env_.CDP_URL + '/icp/deleteIcpRules';
    if (rowData && tenantData){
      data.map(() => {
        IdArray.push(rowData)
        IdArray.push(tenantData)
        console.log('Row Array:', IdArray)
      });  
    } else if(tenantData){
      data.map(() => {
        console.log('Ten:',rowData)
        IdArray.push(tenantData)
        console.log('Row Array:', IdArray)
      });
    } else if(rowData){
      data.map(() => {
        console.log('Row:',rowData)
        IdArray.push(rowData)
        console.log('Row Array:', IdArray)
      });
    }
    const response = await axios.post(url, {IdArray}, {withCredentials: true});
  } catch (err) {
    console.log(err);
    return ;
  }
}

export const bulkCreateIcpRules = async (data) => {
  const jsonData = [];
  for (const obj of data) {
    try {
      if (obj.rule && typeof JSON.stringify(obj.rule) === 'string') {
        jsonData.push(obj);
      }
    } catch (error) {
      console.log('unable to parse');
    }
  }
  console.log('JSON data in api.js after processing', jsonData);
  try {
    const url = window._env_.CDP_URL + '/icp/bulkUploadIcpRules';
    const response = await axios.post(url, jsonData, { withCredentials: true });
    return response;
  } catch (error) {
    console.log(error);
  }    
}

export const getAttributeData = async (tenantId) => {
  try {
    // const url = window._env_.METADATA_URL + '/attribute/getAttributeData';
    console.log('TenantId passed', tenantId)
    const url = `${window._env_.METADATA_URL}/attribute/getAttributeData/?tenantId=${tenantId}`;
    const data = await axios.get(url, { withCredentials: true });
    return data;
  } catch (err) {
    console.log(err);
    return ;
  }
}

export const NewAttribute = async (data) => {
  try {
    const url = window._env_.METADATA_URL + '/attribute/newAttributeData';
    const response = await axios.post(url, {
      parent: data.parent,
      child: data.child,
      parent_value: data.parentValue,
      child_value: data.childValue,
      tenantId: data.tenantId,
    }, { withCredentials: true });
    return response;
  } catch (err) {
    console.log(err);
    return ;
  }
}

export const DeleteAttribute = async (data) => {
  try {
    const rowData = data[0].id;
    const tenantData = data[0].tenantId;
    const idArray = [];
    const url = window._env_.METADATA_URL + '/attribute/deleteAttributeData';
    if (rowData && tenantData){
      data.map(() => {
        idArray.push(rowData)
        idArray.push(tenantData)
      });  
    } else if(tenantData){
      data.map(() => {
        idArray.push(tenantData)
      });
    } else if(rowData){
      data.map(() => {
        idArray.push(rowData)
      });
    }
    console.log(idArray);
    const response = await axios.post(url, {idArray}, {withCredentials: true});
  } catch (err) {
    console.log(err);
    return ;
  }
}

export const BulkUploadAttributeData = async (data) => {
  const jsonData = [];
  for (const obj of data){
    // console.log('Type of: ', typeof JSON.parse(JSON.stringify(obj.parent)));
    try {
      JSON.parse(JSON.stringify(obj.parent));
      if ((obj.parent && typeof JSON.parse(JSON.stringify(obj.parent)) === 'string') || (obj.parent === "")){
        jsonData.push(obj)
      }
    } catch (err) {
      console.log('Unable to parse');
    }
    // if ((obj.parent && typeof obj.parent === 'string') || (obj.parent === "")){
    //   jsonData.push(obj);
    // }
    // try {
    //   JSON.parse(obj.parent);
    //   if ((obj.parent && typeof JSON.parse(obj.parent) === 'string') || (obj.parent === "")){
    //     jsonData.push(obj)
    //   }
    // } catch (err) {
    //   console.log('Unable to parse');
    // }
  }
  console.log('JSON data in api.js after processing', jsonData);
  try {
    const url = window._env_.METADATA_URL + '/attribute/bulkUploadAttribute'
    const response = await axios.post(url, jsonData, { withCredentials: true });
    return response;
  } catch (err) {
    console.log(err);
  }
}