import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {AuthProvider} from "@bamboobox/b2logincheck";
import UITheme from "@bamboobox/b2-theme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { HashRouter as Router } from "react-router-dom";
import {ResourceCheck} from '@bamboobox/b2resource-check/dist'
import { AUTH_URL } from "./constant";

//import {  ThemeProvider } from '@mui/material/styles';
const resources=["users_userManagement",
"users_roleManagement",
"users_tenantConfig",
"users_tenantConfigJson",
"users_fieldMapper",
"users_actionLabels",
"users_icpRules",
"users_attributes",
"users_dataAccessGroup","users"]

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider authcheckURL={AUTH_URL}>
      <ThemeProvider theme = {UITheme}>
      <Router>
      <ResourceCheck component={<App />} resources={resources} />
      {/* <App /> */}
      </Router>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

