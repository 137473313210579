import { Button } from '@mui/material';
import React from 'react'
import { resourceCreation, roleCreation, roleToResourceCreationBulk, userCreation,bulkUserCreation, userToRoleCreationBulk } from '../api';
import UploadComponent from './UploadComponent';
import { DataGrid } from "@material-ui/data-grid";
import TemplateDownload from './TemplateDownload';
import "./bulk.css"

let allFiles = [
    {label: 'Users', funcDef:bulkUserCreation, mandatoryTemplateColumns:['firstName','lastName','email','department']},
    
];



const columns = [
      {
        field: "label",
        headerName: "Upload Type",
        width: 470,
        editable: true,
      },
      {
        field: "template",
        headerName: "Sample Templete",
        width: 300,
        renderCell: (params) => {
          return (
            <div style={{ cursor: "pointer",display: "flex", justifyContent: "space-evenly" }}>
              <TemplateDownload mandatoryColumns={params.row.mandatoryTemplateColumns} label={params.row.label}/> 
            </div>
          );
        },
      },
      {
        field: "mandatoryTemplateColumns",
        headerName: "Upload Files to Create",
        width: 300,
        renderCell: (params) => {
          return (
            <div style={{ cursor: "pointer",display: "flex", justifyContent: "space-evenly" }}>
              <UploadComponent fileName={params.row.label} creationCall={params.row.funcDef}/>
            </div>
          );
        },
      },
]

const BulkUploadComponent = (props) => {
  const handleClick=()=>{
    props.renderHandler();
    props.setshowBulkScreen(false);
  }
  return (
    <div className="upload">
        <DataGrid
            
            getRowId={(r) => r.label}
            rows={allFiles}
            columns={columns}
            aria-label="The Bulk User Data"
            rowsPerPageOptions={[1]}
            pageSize={1}
            sx={{ height:200 }}
        />
        {/* {allFiles.map(fileDetails => )} */}
        <Button style={{  fontSize: "12px",
    backgroundColor: "rgb(33, 218, 140)",
    borderColor: "rgb(33, 218, 140)",
    color: "rgb(255, 255, 255)",
    borderRadius: "18px",
    margin:"10px 40px"
    }} onClick={handleClick} >BacK to List</Button>
    </div>
  )
}

export default BulkUploadComponent