import React,{ useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import { Button, Dialog, DialogActions } from '@mui/material';
import { bulkCreateIcpRules } from '../api';

const useStyles = makeStyles((theme) => ({
    largeBtn: {
        padding: 10,
        width: 140,
        margin:"0px 5px"
      },
    inputHolder:{
        display:"flex",
        width:"100%",
        alignItems:"center",
        justifyContent:"center",
        margin:"20px 0px"
    },
    paperHolder:{
      height:"250px",
      width:"800px",
      padding:"30px 0px",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      margin:"auto",
      borderRadius:"20px"
    },
    btnHolder:{
      margin:"20px"
    },
    uploadFile: {
      border: "1px solid #ccc",
      display: "inline-block",
      // padding: "6px 12px",
      cursor: "pointer"
    }
  }));

const ImportIcpRules = () => {
    const [jsonFile, setJsonFile] = useState();
    const [open,setOpen]=useState(false);
    const classes=useStyles();

    const handleSubmit = () => {
      if (jsonFile) {
        const reader = new FileReader();
  
        reader.onload = async function (e) {
          const text = e.target.result;
          let JsonData;
          try {
            JsonData=JSON.parse(text);
            JsonData.map((row) => {
              try {
                row.rule=JSON.parse(row.rule);
              } catch (error) {}
            })
          } catch (error) {
            alert("File is not JSON type");
          }
          try {
            const response = await bulkCreateIcpRules(JsonData);
            setOpen(false)
          } catch (error) {
            console.log(error);
          }
        };
        reader.readAsText(jsonFile);
      }
      else{
        alert("please choose a file first")
      }
    };

    const handleClose=()=>{
      setOpen(false);
    }
  
    return (
     <>
     <Button style={{  fontSize: "12px",
                       backgroundColor: "rgb(33, 218, 140)",
                       borderColor: "rgb(33, 218, 140)",
                       color: "rgb(255, 255, 255)",
                       borderRadius: "18px",
                       }}onClick={()=>{setOpen(true)}}>Upload File</Button>
     <Dialog open={open} aria-labelledby="form-dialog-title" className='upload'>
         <div>
         <section className="dropzone_container" style={{margin:"auto"}}>
      <div className='dropzone'>
        {/* <p style={{fontWeight: "700", fontSize: "16px", color: "#184D27"}}><span style={{textDecoration: "underline"}}>Browse</span></p> */}
        {/* Check setJsonFile */}
        <input  type="file" accept=".json" onChange={(e) => setJsonFile(e.target.files[0])} />
        <p style={{fontWeight: "500", fontSize: "16px",color: "#030303", opacity: "0.5" }}>Only .json files will be allowed</p>
      </div>
      <aside>
        {/* <h4>Files</h4> */}
        {/* <ul>{files}</ul> */}
      </aside>
    </section>
         </div>
         <DialogActions style={{display:"flex",alignItems:"flex-end",left:"350px",position:"relative",paddingTop:"24px"}}>
             <Button variant="contained" onClick={handleClose}  style={{  fontSize: "12px",
                          backgroundColor: "#DDDDDD",
                          borderColor: "rgb(33, 218, 140)",
                          color: "black",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}> Cancel </Button>
             <Button variant="contained" onClick={handleSubmit}  style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}> Create </Button>
         </DialogActions>
     </Dialog>
    </>
    );
}

export default ImportIcpRules;