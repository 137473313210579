/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Typography, IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { useAuthState } from '@bamboobox/b2logincheck';
import { Button } from '@mui/material';
import { getAllActionData, deleteActions } from '../api';
import './actionLabels.css';
import EditActionLabels from './EditActionLabels';
import ConfigExport from '../tenantConfig/tenantConfigv1/ConfigExport';
import NewActionLabels from './NewAction';
import BulkActionScreens from './BulkActionScreens';
const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1em',
    alignItems: 'center'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  smallCont: {
    height: 450,
    width: '100%',
    margin: '0 1em 1em'
  },
  '&.MuiDataGrid-root': {
    height: '95%'
  }
}));

const ActionLabelsData = () => {
  const [rows, setRows] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [render, setRender] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [showBulk, setShowBulk] = useState(false);
  const userData = useAuthState().user;
  const tenantId = userData.tenantId;
  const classes = useStyles();
  const [sortModel, setSortModel] = useState([
    {
      field: 'serviceName',
      sort: 'asc'
    }
  ]);
  const columns = [
    {
      field: 'serviceName',
      headerName: 'Service-Name',
      width: 200
    },
    // {
    //   field:"serviceId",
    //   headerName:"Service-Id",
    //   width:300,
    // },
    {
      field: 'serviceBaseurl',
      headerName: 'Service-baseurl',
      width: 300
    },
    {
      field: 'actionName',
      headerName: 'Action-name',
      width: 200,
      editable: true
    },
    {
      field: 'actionLabel',
      headerName: 'Action-label',
      width: 200,
      editable: true
    },
    {
      field: 'action_details',
      headerName: 'Action-Details',
      width: 200,
      editable: true
    },
    {
      field: 'supportedType',
      headerName: 'Suppported-type',
      width: 200,
      editable: true
    },
    {
      field: 'publicVisibilty',
      headerName: 'Public-visilibity',
      width: 180,
      editable: true
    },
    {
      field: 'Actions',
      headerName: 'Edit',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <EditActionLabels
              actionDetails={params.row.action_details}
              serviceName={params.row.serviceName}
              serviceBaseUrl={params.row.serviceBaseurl}
              actionLabel={params.row.actionLabel}
              actionName={params.row.actionName}
              renderHandler={renderHandler}
              publicVisibility={params.row.publicVisibilty}
              supportedType={params.row.supportedType}
              id={params.row.id}
              serviceId={params.row.serviceId}
            />
          </>
        );
      }
    }
  ];

  function renderHandler() {
    setRender(!render);
    //console.log(render);
  }

  const onRowsSelectionHandler = (ids) => {
    const selectedIds = new Set(ids);
    const selectedRowsData = rows.filter((row) => selectedIds.has(row.id));
    console.log('......................', selectedRowsData);
    setSelectedData(selectedRowsData);
  };

  const handleDelete = async () => {
    console.log('///', selectedData);
    const response = await deleteActions(selectedData);
    renderHandler();
  };
  useEffect(async () => {
    setIsloading(true);
    try {
      const response = await getAllActionData();
      response.data.map((row) => {
        console.log(row.actionDetails);
        row.action_details = JSON.stringify(row.actionDetails);
        return row;
      });
      setRows(response.data);
      setIsloading(false);
    } catch (err) {
      console.log(err);
      alert('failed to fetch data please refresh and try again');
      setIsloading(false);
    }
  }, [render]);

  return (
    <>
      {showBulk ? (
        <Container className={classes.smallCont}>
          {/* <BulkOperation bulkCreateConfig={bulkCreateConfigJson} renderHandler={renderHandler} setShowBulk={setShowBulk} />  */}
          {/* <BulkTenantJson renderHandler={renderHandler} setShowBulk={setShowBulk}  /> */}
          <BulkActionScreens
            renderHandler={renderHandler}
            setShowBulk={setShowBulk}
          />
        </Container>
      ) : (
        <Container>
          <div className={classes.header} style={{ margin: '10px 10px' }}>
            <Typography
              variant="h6"
              style={{
                overflowY: 'hidden',
                fontSize: '20px',
                color: 'rgb(89, 89, 89)',
                fontWeight: 700
              }}
            >
              Actions-Label-Data
            </Typography>
            <div className={classes.buttons}>
              {/* <IconButton onClick={handleDelete} style={{marginRight:"20px"}}>
                        <DeleteIcon style={{color:"rgb(33, 218, 140)"}}fontSize="large"   />
                    </IconButton>
                    
                    <Button variant="contained" color="primary"  style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"20px"
                          }} onClick={()=>setShowBulk(true)} >Bulk Config Upload</Button>
                    <NewConfigJson renderHandler={renderHandler}/> */}
              <IconButton
                onClick={handleDelete}
                style={{ marginRight: '20px' }}
              >
                <DeleteIcon
                  style={{ color: 'rgb(33, 218, 140)' }}
                  fontSize="large"
                />
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                style={{
                  fontSize: '12px',
                  backgroundColor: 'rgb(33, 218, 140)',
                  borderColor: 'rgb(33, 218, 140)',
                  color: 'rgb(255, 255, 255)',
                  borderRadius: '18px',
                  marginRight: '20px'
                }}
                onClick={() => setShowBulk(true)}
              >
                Bulk Actions Upload
              </Button>
              {selectedData.length === 0 ? (
                <ConfigExport
                  data={rows}
                  type={'JSON'}
                  title={'Download Config'}
                />
              ) : (
                <ConfigExport
                  data={selectedData}
                  type={'JSON'}
                  title={'Download Config'}
                />
              )}
              {/* <ConfigExport
                data={rows}
                type={'JSON_Actions'}
                title={'Download Actions'}
              /> */}
              <NewActionLabels renderHandler={renderHandler} />
            </div>
          </div>
          <Container
            style={{
              height: '640px',
              boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 16px',
              background: 'rgb(255, 255, 255)',
              borderRadius: '15px'
            }}
          >
            <DataGrid
              className="actions"
              getRowId={(r) => r.id}
              rows={rows}
              getRowHeight={() => 'auto'}
              columns={columns}
              aria-label="The User Data"
              pageSize={10}
              checkboxSelection
              rowsPerPageOptions={[10]}
              loading={isloading}
              disableSelectionOnClick={true}
              sortModel={sortModel}
              //experimentalFeatures={{ newEditingApi: true }}
              //onCellEditCommit={params=>handleEdit(params)}
              onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
            />
          </Container>
        </Container>
      )}
    </>
  );
};

export default ActionLabelsData;
