import React from "react";
import { Button } from '@mui/material';
import { DataGrid } from "@material-ui/data-grid";
import ImportIcpRules from "./ImportIcpRules.js";

let allFiles = [
    {label: 'ICP_Rules - JSON Data Upload'},
];

const columns = [
      {
        field: "label",
        headerName: "Upload Type",
        width: 470,
        editable: true,
      },{
        field: "mandatoryTemplateColumns",
        headerName: "Upload Files to Create",
        width: 300,
        renderCell: (params) => {
          return (
            <div style={{ cursor: "pointer",display: "flex", justifyContent: "space-evenly" }}>
              <ImportIcpRules />
            </div>
          );
        },
      },
]

const ExportIcpRules = (props) => {

    const handleClick=()=>{
      props.renderHandler();
      props.setShowBulk(false);
    }

    return (
      <div className="upload">
        <DataGrid  
            getRowId={(r) => r.label}
            rows={allFiles}
            columns={columns}
            aria-label="Bulk ICP Rules"
            rowsPerPageOptions={[1]}
            pageSize={1}
            sx={{ height:200 }}
        />
          <Button 
            variant="contained" 
            onClick={handleClick} 
            style={{  fontSize: "12px",
                      backgroundColor: "rgb(33, 218, 140)",
                      borderColor: "rgb(33, 218, 140)",
                      color: "rgb(255, 255, 255)",
                      borderRadius: "18px",
                      marginLeft: '10px'
                  }} > Back to list </Button>
      </div>
    )
  }
  
  export default ExportIcpRules;