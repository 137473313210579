import { Dialog, DialogActions, Typography } from '@material-ui/core'
import { Button } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { userCreation,bulkUserCreation } from '../api';
import DropZone from './DropZone';
import { useAuthState } from "@bamboobox/b2logincheck";
import "./upload.css"

const UploadComponent = (props) => {
    const [open, setOpen] = useState(false);
    const [selectedFileData, setSelectedFileData] = useState([]);
    const userData = useAuthState().user;

    const handleClose = () => {
        setOpen(false);
    }
    const handleSave = async() => {
        console.log('selectedFileData',selectedFileData);
        const userArray=[];
        console.log("selectedfiles",selectedFileData);
        for(let i=0; i<selectedFileData.length;i++){
            let tempObj = selectedFileData[i];
            //console.log("csv data",tempObj);
            userArray.push(tempObj);
            //props.creationCall(tempObj);
        }
        await props.creationCall(userArray);
        setOpen(false);
    }
  return (
    <>
        <Button style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          }}onClick={()=>{setOpen(true)}}>Upload File</Button>
        <Dialog open={open} aria-labelledby="form-dialog-title" className='upload'>
            <div >
                <DropZone setSelectedFileData={setSelectedFileData} />
            </div>
            <DialogActions style={{display:"flex",alignItems:"flex-end",left:"350px",position:"relative",paddingTop:"24px"}}>
                <Button variant="contained" onClick={handleClose}  style={{  fontSize: "12px",
                          backgroundColor: "#DDDDDD",
                          borderColor: "rgb(33, 218, 140)",
                          color: "black",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSave} style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    </>
  )
}

export default UploadComponent