/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import { roleCreation } from '../../api';
import {Typography} from '@material-ui/core';
import "./editResources.css"
import { StepContext } from '@mui/material';
import { useAuthState } from "@bamboobox/b2logincheck";
const useStyles = makeStyles((theme) => ({
  largeBtn: {
    width: 140,
  },
  root : {
    width: "600px",
    marginLeft:45,
  },
  formControl: {
    //margin: theme.spacing(1),
    margin:"0.8rem",
    width: 500,
    marginLeft: theme.spacing(0),
  },
  dialogContent:{
    margin: "auto",
    marginTop: "30px",
  },
  paraV:{
    color:"#ff0000",
    display:"block"
  },
  paraH:{
    display:"none"
  },
}));

export default function NewRole(props) {
  const [open, setOpen] = useState(false);
  const logindata = useAuthState();
  //const userData = useAuthState();
  const [resources, setResources] = useState([]);
  const [role, setRole] = useState("");
  const [roleDescription,setRoleDescription]=useState("")
  const [selectedResources,setSelectedResources]=useState([]);
  const [showError,setShowError]=useState(false);
  const [roleError,setRoleError]=useState(false);
  const [roleText,setRoleText]=useState("");
  const[desError,setDesError]=useState(false);
  const[desText,setDesText]=useState("");
  const url = window._env_.LOGIN_API_URL+"/resourceoperation/getallresourceoperation";
  const classes=useStyles()

  const handleClickOpen = async() => {
    if(!resources.length>0){
      try {
        const response=await fetch(url,{
          method:"GET",
          credentials:"include",
        })
        
        const data=await response.json();
          let dummyDatalog=logindata?.permissions?.filter((obj)=>obj.resourceName==='tenant_creation');
          console.log("-------------------------",dummyDatalog);
          if(dummyDatalog.length){
            setResources(data)
            console.log("data in NewRole..............",data)

          }else{
            let dataaa=data.filter((obj)=>obj.resourceName!=='tenant_creation')
            console.log("dataaaaaa in NewRole..............",dataaa)
            setResources(dataaa)
          }
      } catch (error) {
        console.log(error);
        setResources([])
      }
    }
    setOpen(true);
  };
  const handleClose = (e) => {
    if(e._reactName === "onClick"){
      setOpen(false);
    }
  };
  const handleRole=(e)=>{
    if(e.target.value?.length>128){
      setRoleError(true);
      setRoleText("max-length allowed is 128")
      return;
    }
    if(e.target.value===""){
      setRoleError(true);
      setRoleText("required")
    }else{
      setRoleError(false);
      setRoleText("");
    }
    setRole(e.target.value)
  }
  const handleDes=(e)=>{
    if(e.target.value?.length>128){
      setDesError(true);
      setDesText("max-length allowed is 128")
      return;
    }
    if(e.target.value===""){
      setDesError(true);
      setDesText("required")
    }else{
      setDesError(false);
      setDesText("");
    }
    setRoleDescription(e.target.value)
  }
  const handleSave = async() => {
    if(!role && !roleDescription){
      setRoleError(true);
      setRoleText("required");
      setDesError(true);
      setDesText("required")
    }
    else if(!role){
      setRoleError(true);
      setRoleText("required");
    }else if(!roleDescription){
      setDesError(true);
      setDesText("required")
    }
    else{
      const data={
        "roleName":role,
        "roleDescription":roleDescription,
        "resources":selectedResources
      }
      console.log("roleData",data)
      try {
        const response=await roleCreation(data);
        if(response?.data?.success===false){
          props.setMessage(response?.data?.message || "unable to create try again");
          props.setOpen(true);
        }else{
          setRole("");
          setRoleDescription("");
          setSelectedResources([]);
          props.renderHandler();
          props.setMessage("Created Successfully");
          props.setOpen(true);
        }
       
        
      } catch (error) {
        props.setMessage("Unable to create try again");
        props.setOpen(true);
      }
    }
  };
  const handleChange=(prop)=>{
    console.log(prop);
    setSelectedResources(prop)
  }

  const optionDisabled=(option)=>{
    const names=resources.map((row)=>{return row.resourceId});
    console.log(names.includes(option.resourceOperationId))
    return names.includes(option.resourceOperationId);
  }
  return (
    <div style={{display:"flex",
    alignItems:"center",
    justifyContent:"center"}}>
      <Button variant="contained" onClick={handleClickOpen} style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"5px 10px"
                      }} >
        +New Role
      </Button>
      <Dialog
        className="editResource"
        open={open}
        onClose={e=>handleClose(e)}
        aria-labelledby="alert-dialog-title new"
        aria-describedby="alert-dialog-description new"
        disableEscapeKeyDown={true}
      >
        <div className={classes.dialogContent}>
        <DialogTitle id="alert-dialog-title" style={{padding:"0px 24px "}}>{"New Role"}</DialogTitle>
        <DialogContent  >
          <DialogContentText id="alert-dialog-description">
            <form >
              <div>
                <FormControl variant="outlined" required={true} >
                <label style={{color:"#212121"}}>RoleName</label>
                 <TextField
                    id="outlined-disabled"
                    value={role}
                    error={roleError}
                    helperText={roleText}
                    onChange={e=>handleRole(e)}
                    style={{width:300}}
                />
                </FormControl>
              </div>
              <div>
                <FormControl variant="outlined" required>
                <label style={{color:"#212121"}}>Role Description</label>
                 <TextField
                    id="outlined-disabled"
                    value={roleDescription}
                    error={desError}
                    helperText={desText}
                    onChange={e=>handleDes(e)}
                    style={{width:300}}
                />
                </FormControl>
              </div>
              <FormControl variant="outlined" >
              <div>
                   <label style={{color:"#212121"}}>Resources</label>
              <Autocomplete
                    multiple
                    freeSolo
                    limitTags={4}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    onChange={(e,newValue)=>handleChange(newValue)}
                    id="multiple-limit-tags"
                    options={!resources ? [{label:"Loading...", id:0}] :resources}
                    getOptionLabel={(option) => option.resourceName+" : "+option.operationName}
                    value={selectedResources}
                    getOptionDisabled={(option)=>optionDisabled(option)}
                    renderInput={(params) => (
                    <TextField {...params} variant="outlined"/>
                    )}
                    sx={{width:500}}
                /> 
                </div>
              </FormControl>
              <Typography className={showError?classes.paraV:classes.paraH} component={'span'} variant={'body2'}>
                  Name and description cannot be empty</Typography>
              <DialogActions style={{margin:"auto",marginTop:"20px",justifyContent:"space-around" ,width:"400px"}}>
                <Button
                className={classes.largeBtn}
                  onClick={handleClose}
                  variant="contained"
                  style={{  fontSize: "12px",
                          backgroundColor: "#DDDDDD",
                          borderColor: "rgb(33, 218, 140)",
                          color: "black",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}
                >
                  Close
                </Button>
                <Button
                className={classes.largeBtn}
                  onClick={handleSave}
                  variant="contained"
                  style={{  fontSize: "12px",
                  backgroundColor: "rgb(33, 218, 140)",
                  borderColor: "rgb(33, 218, 140)",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "18px",
                  marginRight:"10px",
                  padding:"8px 10px"
              }}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

