/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Typography, IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import NewConfigJson from './NewConfigJson';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { useAuthState } from '@bamboobox/b2logincheck';
//import { deleteConfig, } from '../../api';
//import EditDialog from "../tenantConfigv1/editConfig/EditDialog";
import { getAllTenantConfigJson } from '../../api';
import EditConfigJson from './EditConfigJson';
import DeleteConfigJson from './DeleteConfigJson';
import BulkOperation from '../tenantConfigv1/BulkOperation';
import { bulkCreateConfigJson } from '../../api';
import { Button } from '@mui/material';
import { deleteConfigJson } from '../../api';
import ConfigExport from '../tenantConfigv1/ConfigExport';
import BulkTenantJson from './BulkTenantJson';
const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1em',
    alignItems: 'center'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  smallCont: {
    height: 450,
    width: '100%',
    margin: '0 1em 1em'
  },
  '&.MuiDataGrid-root': {
    height: '95%'
  }
}));

const TenantConfigV1 = () => {
  const [rows, setRows] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [render, setRender] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [showBulk, setShowBulk] = useState(false);
  const userData = useAuthState().user;
  const tenantId = userData.tenantId;
  const classes = useStyles();
  const [sortModel, setSortModel] = useState([
    {
      field: 'tool',
      sort: 'asc'
    }
  ]);
  const columns = [
    {
      field: 'tool',
      headerName: 'Tool',
      width: 150
    },
    {
      field: 'configKey',
      headerName: 'Config-Key',
      width: 300
    },
    {
      field: 'configVal',
      headerName: 'Config-Values',
      width: 500,
      flex: 1,
      editable: true
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <EditConfigJson
              rowId={params.row.rowId}
              tool={params.row.tool}
              configKey={params.row.configKey}
              configVal={params.row.configVal}
              renderHandler={renderHandler}
            />
            <DeleteConfigJson
              rowId={params.row.rowId}
              renderHandler={renderHandler}
            />
          </>
        );
      }
    }
  ];
  function renderHandler() {
    setRender(!render);
    //console.log(render);
  }
  const handleEdit = async (params) => {
    // try {
    //     const response=await updateTenantConfig(params)
    //     const data= await response.json()
    //     setRender(true);
    // } catch (error) {
    //     console.log(error)
    //     alert("edit failed due to internal server error please try again");
    // }
  };
  const onRowsSelectionHandler = (ids) => {
    const selectedIds = new Set(ids);
    const selectedRowsData = rows.filter((row) => selectedIds.has(row.rowId));
    setSelectedData(selectedRowsData);
  };

  const handleDelete = async () => {
    const response = await deleteConfigJson(selectedData);
    renderHandler();
  };
  useEffect(async () => {
    setIsloading(true);
    try {
      const response = await getAllTenantConfigJson();
      response.map((row) => {
        row.configVal = JSON.stringify(row.configVal, null, 2);
        return row;
      });
      setRows(response);
      setIsloading(false);
    } catch (err) {
      console.log(err);
      alert('failed to fetch data please refresh and try again');
      setIsloading(false);
    }
  }, [render]);

  return (
    <>
      {showBulk ? (
        <Container className={classes.smallCont}>
          {/* <BulkOperation bulkCreateConfig={bulkCreateConfigJson} renderHandler={renderHandler} setShowBulk={setShowBulk} />  */}
          <BulkTenantJson
            renderHandler={renderHandler}
            setShowBulk={setShowBulk}
          />
        </Container>
      ) : (
        <Container>
          <div className={classes.header} style={{ margin: '10px 10px' }}>
            <Typography
              variant="h6"
              style={{
                fontSize: '20px',
                color: 'rgb(89, 89, 89)',
                fontWeight: 700
              }}
            >
              Tenant Config Json
            </Typography>
            <div className={classes.buttons}>
              <IconButton
                onClick={handleDelete}
                style={{ marginRight: '20px' }}
              >
                <DeleteIcon
                  style={{ color: 'rgb(33, 218, 140)' }}
                  fontSize="large"
                />
              </IconButton>
              {selectedData.length === 0 ? (
                <ConfigExport
                  data={rows}
                  type={'JSON'}
                  title={'Download Config'}
                />
              ) : (
                <ConfigExport
                  data={selectedData}
                  type={'JSON'}
                  title={'Download Config'}
                />
              )}

              <Button
                variant="contained"
                color="primary"
                style={{
                  fontSize: '12px',
                  backgroundColor: 'rgb(33, 218, 140)',
                  borderColor: 'rgb(33, 218, 140)',
                  color: 'rgb(255, 255, 255)',
                  borderRadius: '18px',
                  marginRight: '20px'
                }}
                onClick={() => setShowBulk(true)}
              >
                Bulk Config Upload
              </Button>
              <NewConfigJson renderHandler={renderHandler} />
            </div>
          </div>
          <Container
            style={{
              height: '640px',
              boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 16px',
              background: 'rgb(255, 255, 255)',
              borderRadius: '15px'
            }}
          >
            <DataGrid
              getRowId={(r) => r.rowId}
              rows={rows}
              getRowHeight={() => 'auto'}
              columns={columns}
              aria-label="The User Data"
              pageSize={10}
              checkboxSelection
              rowsPerPageOptions={[10]}
              loading={isloading}
              disableSelectionOnClick={true}
              sortModel={sortModel}
              //experimentalFeatures={{ newEditingApi: true }}
              //onCellEditCommit={params=>handleEdit(params)}
              onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
            />
          </Container>
        </Container>
      )}
    </>
  );
};

export default TenantConfigV1;
