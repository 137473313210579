import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import UserManagement from './User/UserManagement';
import RoleManagement from './Roles/RoleManagement/RoleManagement';
import { Typography } from '@material-ui/core';
import TenantConfigV1 from './tenantConfig/tenantConfigv1/TenantConfigV1';
import TenantConfigJsonV1 from "./tenantConfig/tenantConfigJsonV1/TenantConfigJsonV1"
import TenantLabelsV1 from "./tenantConfig/tenantLabels/TenantLabelsV1"
import {useAuthState} from "@bamboobox/b2logincheck";
import AccessError from './AccessError'
import { useLocation } from "react-router-dom";
import "./app.css"

import {ThemeProvider } from '@mui/system';
import ActionLabelsData from './ActionLabels/ActionLabels';
import theme from '@bamboobox/b2-theme'
import CreateTenant from "./tenantCreation/CreateTenant"
import ICP from './ICP/IcpRules';
import Attribute from './Attributes/Attribute.js';
import SegmentComponent from '@bamboobox/b2-segment-manager-component/dist';

function TabPanel(props) {
  const { children, value, index, ...other } = props;   
  return (
    <div
      
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other} >
         {/* {!isLatestVersion && (
        <p>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              emptyCacheStorage();
            }}
          >
            New Version get deployed !
          </a>
        </p>
      )} */}
      {value === index && (
        <Box p={5} >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ResourceMap={
  "users_userManagement": "User Management",
  "users_roleManagement":"Role Management",
  "users_dataAccessGroup":"Data Access Group",
  "users_tenantConfig":"Tenant Config",
  "users_tenantConfigJson":"Tenant Config Json",
  "users_fieldMapper":"Field Mapper",
  "users_actionLabels":"Action Labels",
  "users_icpRules":"ICP Rules",
  "users_attributes":"Attributes",
  
}







export default function App() {
      let location = useLocation();
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    let GetIcp = () => {
        return useQuery().get("icp") || '';
    }
    let params_url = useQuery().get("icp") || "";
    const [icp, setIcp] = React.useState(params_url);


    React.useEffect(() => {
      console.log("test icp query changed",icp)
      setIcp(GetIcp())
  }, [useQuery().get("icp")])
  const [helpPermission,setHelpPermission]=React.useState(false)
  const [value, setValue] = React.useState(0);
  const logindata=useAuthState();
  const name='DAG';
  
  const tenantId=logindata.user.tenantId;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Urls={
    LOGIN_API_URL:window._env_.LOGIN_API_URL,
    TENANT_CONFIG:window._env_.TENANT_CONFIG,
    AUTH_URL:window._env_.AUTH_URL,
    ACTIVATION_SERVICE:window._env_.ACTIVATION_SERVICE,
    REACT_APP_ENV:window._env_.REACT_APP_ENV,
    FILTERS_BASE_URL:window._env_.FILTERS_BASE_URL,
    UNIFIEDPROFILE:window._env_.UNIFIEDPROFILE,
    PROS_BASE_URL: window._env_.PROS_BASE_URL,
    META_BASE_URL: window._env_.META_BASE_URL,
    CDP_BASE_URL: window._env_.CDP_BASE_URL,
    ENG_SCR_BASE_URL: window._env_.ENG_SCR_BASE_URL,
    INTENT_TOPICS_URL: window._env_.INTENT_TOPICS_URL,
    AGGREGATOR_API: window._env_.AGGREGATOR_API,
    B2_INTENT_SETTING:window._env_.B2_INTENT_SETTING,
    FILTER_VALUES_LIMIT:window._env_.FILTER_VALUES_LIMIT
  }

  console.log("logindata",tenantId,Urls,name,icp);
  const componentMap={
    "User Management":  <UserManagement />,
    "Role Management": <RoleManagement />,
    "Data Access Group":<SegmentComponent icp={icp} tenantId={tenantId} API={Urls} name={name} />,
    "Tenant Config":<TenantConfigV1/>,
    "Tenant Config Json":<TenantConfigJsonV1/>,
    "Field Mapper":<TenantLabelsV1/>,
    "Action Labels":<ActionLabelsData/>,
    "ICP Rules":<ICP/>,
    "Attributes":<Attribute />,
    
  }
  const permissionsOfUser = logindata?.permissions.map((obj)=>obj.resourceName.toLowerCase());
  let resourcesAllowed=[];
  const permissionCheck=()=>{
    // if(window._env_.REACT_APP_ENV==='development'){
    //   resourcesAllowed=Object.values(ResourceMap);
    // }
    if(permissionsOfUser.includes('users')){
      resourcesAllowed=Object.values(ResourceMap);
    }else{
      Object.keys(ResourceMap).forEach((obj)=>{
        if(permissionsOfUser.includes(obj.toLowerCase())){
          resourcesAllowed.push(ResourceMap[obj]);
        }
      })
    }
    
    // resourcesAllowed=Object.values(ResourceMap);
    // console.log("resource allowed",resourcesAllowed);
  }
  permissionCheck();
  let dummyDatalog=logindata?.permissions?.filter((obj)=>obj.resourceName==='tenant_creation');
  let resourceTenantCreate=dummyDatalog?.[0]?.resourceName
  console.log(resourcesAllowed);
  useEffect(()=>{
      
    if(resourceTenantCreate==="tenant_creation")
        setHelpPermission(true)
      }
  ,[helpPermission])
  

  return (
    <ThemeProvider theme={theme}>
  
    <h6 style={{ fontSize: "20px",color: "rgb(89, 89, 89)",fontWeight: 550,padding:"10px 25px"}}>Admin-Settings</h6>
    <Tabs
    role="tabpanel"
      value={value}
      onChange={handleChange}
      TabIndicatorProps={{style:{backgroundColor:"rgb(33, 218, 140)",height:"4px",borderRadius:"3px"}}}
      aria-label="Vertical tabs example"
      className="tabs">
      {
        resourcesAllowed.map((label,index)=>{
          return(
            <Tab style={{minWidth: "140px"}} label={label} {...a11yProps(index)} />
          )
        })
      }
      {/* <Tab style={{minWidth: "140px"}} label={"User Management"} {...a11yProps(0)} />
      <Tab style={{minWidth: "140px"}} label={"Role Management"} {...a11yProps(1)} />
      <Tab style={{minWidth: "140px"}} label={"Tenant Config"} {...a11yProps(2)} />
      <Tab style={{minWidth: "140px"}} label={"Tenant Config Json"} {...a11yProps(3)} />
      <Tab style={{minWidth: "140px"}} label={"Field Mapper"} {...a11yProps(4)} />
      <Tab style={{minWidth: "140px"}} label={"Action Labels"} {...a11yProps(5)}/>
      <Tab style={{minWidth: "140px"}} label={"ICP Rules"} {...a11yProps(6)}/> 
      <Tab style={{minWidth: "140px"}} label={"Attributes"} {...a11yProps(7)}/> 
      {helpPermission?<Tab label={"Tenant Create"} {...a11yProps(8)}/> :<></>}
      <Tab style={{minWidth: "140px"}} label={"Data Access Group"} {...a11yProps(9)} /> */}

    </Tabs>
    {resourcesAllowed.map((label,index)=>{
      return(
        <TabPanel value={value} index={index}>
          {componentMap[label]}
        </TabPanel>
      )
    })}
    
    </ThemeProvider>
  );
}
