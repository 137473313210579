/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Container } from "@material-ui/core";
import token from '../token';
import NewUser from "./NewUser";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles"
import "./UserManagement.css";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";
import BulkCreate from "./BulkCreate";
import BulkUploadComponent from "./BulkUploadComponent";
import {Dialog, DialogContent } from "@mui/material";
import AutoCompleteReadOnlyChip from "../Roles/RoleAssignment/RoleChip";

const useStyles = makeStyles({
  container:{
    height: 650, 
    width: "100%", 
    margin:"0 1em 1em",
  },
  smallCont:{
    height: 250, 
    width: "100%", 
    margin:"0 1em 1em",
  },
  header:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  }
});


function UserManagement() {
  const [isloading, setisloading] = useState(false);
  const [showBulkScreen, setshowBulkScreen] = useState(false);
  const [rows, setRows] = useState([]);
  const [render, setRender] = useState(false);
  const [message,setMessage]=useState("");
  const [open,setOpen]=useState(false)
  const [roles,setRoles]=useState([]);
  const [dag,setDag]=useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);
  const classes = useStyles();
  function renderHandler(){
    setRender(!render);
    //console.log(render);
  }
  const handleClose=()=>{
    setOpen(false);
}
  const columns = [
    {
      field: "name",
      headerName: "User",
      width: 250,
      editable: true,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 320,
      editable: true,
      sortable: true,
    },
    {
      field : "roles",
      headerName: "Roles(User can have multiple roles)",
      width:350,
      sortable: false,
      renderCell: (params) => {
          return (
            <>
              <AutoCompleteReadOnlyChip fieldName="roleName"   roles={params.row.roles}/>
            </>
          );
      },
  },
  {
    field : "dag",
    headerName: "Data Access Group",
    width:350,
    sortable: false,
    renderCell: (params) => {
        return (
          <>
            <AutoCompleteReadOnlyChip fieldName="dagName"  roles={params.row.dag}/>
          </>
        );
    },
},
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer",display: "flex", justifyContent: "space-evenly",alignItems:"center" }}>
            <EditUser setMessage={setMessage} setOpen={setOpen} department={params.row.department} 
            userId={params.row.userUuid} name={params.row.name} email={params.row.email} 
            superUser={params.row.superUser} selectedRoles={params.row.roles} selectedDag={params.row.dag} 
            roles={roles} dag={dag} renderHandler={renderHandler} />
            <DeleteUser  userId={params.row.userUuid} renderHandler={renderHandler}/>
          </div>
        );
      },
    },
  ];


  useEffect( async() => {
    setisloading(true);
    setRows([]);
    try {
      
      const response=await fetch(window._env_.LOGIN_API_URL+"/users/getallusers", {
    //   const response=await fetch(`http://test.bamboobox.in:3001/users/getallusers`, {
        method: "GET",
        credentials: "include",
        //headers: { "auth-token": token },
      })
      const jsonResponse=await response.json()
      if(jsonResponse.success===true){
        setRows(jsonResponse.data);
        setRoles(jsonResponse.roles);
        setDag(jsonResponse.dag);
      }
      setisloading(false);
    } catch (error) {
      setisloading(false)
      setMessage("Please refresh and  try again")
      setOpen(true)
      console.log(error);
    } 
  },[render]);
 
  return (
    <>
    {
      showBulkScreen ? 
      <Container className={classes.smallCont}>
        <BulkUploadComponent setshowBulkScreen={setshowBulkScreen} renderHandler={renderHandler}/>
      </Container>
       :
       <>
       <Dialog
                className="msg"
                onClose={e=>handleClose(e)}
                open={open}
                aria-labelledby="alert-dialog-title edit"
                aria-describedby="alert-dialog-description edit"
                id="custom message"
                >
               <DialogContent style={{margin:"auto",display:"flex"}}>
                <Typography variant="h6">{message}</Typography>
               </DialogContent>
                </Dialog>
      <Container >
        <div className={classes.header} style={{margin:"10px 10px"}}>
          <Typography variant="h6" style={{fontSize: "20px",color: "rgb(89, 89, 89)",fontWeight: 700}}>User List</Typography>
          <div style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
          <BulkCreate setshowBulkScreen={setshowBulkScreen}><Typography>Bulk User Management</Typography></BulkCreate>
          <NewUser setMessage={setMessage} setOpen={setOpen} renderHandler={renderHandler} roles={roles} dag={dag}/>
          </div>
        </div>
        <Container style={{height:"640px",boxShadow:"rgb(0 0 0 / 10%) 0px 0px 16px", background: "rgb(255, 255, 255)",borderRadius:"15px" }}>
        <DataGrid
          getRowId={(r) => r.email}
          rows={rows}
          columns={columns}
          aria-label="The User Data"
          pageSize={10}
          rowsPerPageOptions={[10]}
          editRowsModel
          disableSelectionOnClick
          loading={isloading}
          sortModel={sortModel}
          //ColumnWidth="*"
        />
        </Container>
      </Container>
      </>
}
    </>
  );
}

export default UserManagement;
