import { Button } from '@material-ui/core'
import React from 'react'

const TemplateDownload = (props) => {
  const handleTemplateDownload = () => {
    let dataCSV = [];
    dataCSV.push(props.mandatoryColumns.join(','));
    let csvContent = new Blob([dataCSV.join('\r\n')], { type: 'text/csv' })
    let encodedUri = URL.createObjectURL(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${props.label}_sampletemplate.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
  }
  return (
    <Button style={{  fontSize: "12px",
    backgroundColor: "rgb(33, 218, 140)",
    borderColor: "rgb(33, 218, 140)",
    color: "rgb(255, 255, 255)",
    borderRadius: "18px",
    }} onClick={handleTemplateDownload}>Sample Template</Button>
  )
}

export default TemplateDownload