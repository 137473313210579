import React, { useState } from "react";
import { Button } from '@mui/material'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import { useAuthState } from "@bamboobox/b2logincheck";
import { TextField } from "@mui/material";
import { NewAttribute } from "../api";


const useStyles = makeStyles(theme => ({
  form:{
    display:"flex",
    flexDirection:"column",
    marginLeft:"20px"
  },
  largeBtn: {
      width: 120
  },
  formControl: {
    margin: theme.spacing(1),
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginLeft: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonsHolder:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginTop:"20px"
  },
  col:{
    marginBottom:"10px"
  },
  dialogContent:{
    margin: "auto",
    marginTop: "15px",
  },
 }));

export default function NewAttributeData(props) {
  const classes = useStyles();
  const userData = useAuthState().user;
  const tenantId = userData.tenantId;
  const [open, setOpen] = React.useState(false);
  
  const [parent, setParent] = useState("");
  const [parentError, setParentError]=useState(false);  
  const [parentText, setParentText]=useState("");
  
  const [child, setChild] = useState("");
  const [childError, setChildError]=useState(false);  
  const [childText, setChildText]=useState("");  

  const [parentValue, setParentValue] = useState("");
  const [parentValueError, setParentValueError]=useState(false);
  const [parentValueText, setParentValueText]=useState("");

  const [childValue, setChildValue]=useState("");
  const [childValueError, setChildValueError]=useState(false);
  const [childValueText, setChildValueText]=useState("");

  const handleSave = async() => {
    if(!parent || !child || !parentValue || !childValue){
      setParentError(true);
      setParentText("required");
      setChildError(true);
      setChildText("required")
      setParentValueError(true);
      setParentValueText('required')
      setChildValueError(true)
      setChildValueText('required');
    } else if(!parent){
      setParentError(true);
      setParentText("required");
    } else if(!child){
        setChildError(true);
        setChildText("required")
    } else if(!parentValue){
        setParentValueError(true);
        setParentValueText('required')
    } else if(!childValue){
        setChildValueError(true)
        setChildValueText('required');
    }
    else{
      try {
        const response = await NewAttribute({parent, child, parentValue, childValue, tenantId});
        setParent("");
        setChild("");
        setParentValue("");
        setChildValue("");
        props.renderHandler();
        setOpen(false);
      } catch (error) {
        setOpen(true);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    if(e._reactName === "onClick"){
      setOpen(false);
    }
  };

  const handleParent = (e) => {
    if(e.target.value === ""){
      setParentError(true);
      setParentText("required")
    } else {
      setParentError(false);
      setParentText("");
    }
    setParent(e.target.value)
  }

  const handleChildValue = (e) => {
    if(e.target.value === ""){
      setChildValueError(true);
      setChildValueText("required")
    } else {
      setChildValueError(false);
      setChildValueText("");
    }
    setChildValue(e.target.value)
  }

  const handleParentValue = (e) => {
    if(e.target.value === ""){
      setParentValueError(true);
      setParentValueText("required")
    } else {
      setParentValueError(false);
      setParentValueText("");
    }
    setParentValue(e.target.value)
  }

  const handleChild = (e) => {
    if(e.target.value === ""){
      setChildError(true);
      setChildText("required")
    } else {
      setChildError(false);
      setChildText("");
    }
    setChild(e.target.value)
  }

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          }} > Create New Attribute </Button>
      <Dialog
        open={open}
        onClose={e=>handleClose(e)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Edit"
      >
        <div className={classes.dialogContent}>
        <DialogTitle id="alert-dialog-title">{"New Attribute Data"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">  
          <div>
                <FormControl required={true}>
                <label style={{color:"#212121"}}>Parent</label>
                 <TextField
                    id="outlined-disabled"
                    value={parent}
                    error={parentError}
                    helperText={parentText}
                    onChange={e => handleParent(e) }
                    style={{width:300}}
                    variant="standard"
                />
                </FormControl>
              </div>
            <div>
                <FormControl required={true} >
                <label style={{color:"#212121"}}>Child</label>
                 <TextField
                    id="outlined-disabled"
                    value={child}
                    error={childError}
                    helperText={childText}
                    onChange={e => handleChild(e)}
                    style={{width:300}}
                    variant="standard"
                />
                </FormControl>
            </div>
            <div>
                <FormControl  required={true} >
                <label style={{color:"#212121"}}>Parent Value</label>
                 <TextField
                    id="outlined-disabled"
                    value={parentValue}
                    error={parentValueError}
                    helperText={parentValueText}
                    onChange={e => handleParentValue(e)}
                    style={{width:300}}
                    variant="standard"
                />
                </FormControl>
              </div>
          
              <div>
                <FormControl  required={true} >
                <label style={{color:"#212121"}}>Child Value</label>
                 <TextField
                    id="outlined-disabled"
                    value={childValue}
                    error={childValueError}
                    helperText={childValueText}
                    onChange={e=>handleChildValue(e)}
                    style={{width:300}}
                    variant="standard"
                />
                </FormControl>
              </div>

        <DialogActions className={classes.buttonsHolder}>
          <Button className={classes.largeBtn} onClick={handleClose}  variant="contained" style={{  fontSize: "12px",
                          backgroundColor: "#DDDDDD",
                          borderColor: "rgb(33, 218, 140)",
                          color: "black",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}> Close </Button>
          <Button className={classes.largeBtn} onClick={handleSave} variant="contained" style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}> Create </Button>
        </DialogActions>
          </DialogContentText>
        </DialogContent>
        </div>
      </Dialog>
</div>
  );
}
