import React from 'react';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import token from '../token';
import axios from 'axios';
import "./DeleteUser.css"

const useStyles = makeStyles({
    largeBtn: {
        padding: 10,
        width: 100
    }
});


const DeleteUser = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async() => {
    const url = window._env_.LOGIN_API_URL+"/users/deleteuser";
      const response=await axios.post(url, 
      {
        "userId": props.userId,
      },
    {
      withCredentials: true,
    })
    props.renderHandler();
    setOpen(false);
    
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
            <DeleteIcon style={{color:"rgb(33, 218, 140)"}} />
      </IconButton>
      <Dialog
        className='deleteUser'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>  
            <Typography>Are you sure you want to Delete User</Typography>
        <DialogActions>
          <Button onClick={handleClose} className={classes.largeBtn} variant="contained" color="black">
            Close
          </Button>
          <Button onClick={handleDelete} className={classes.largeBtn} variant="contained" type="submit" color="primary">
            Delete
          </Button>
        </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DeleteUser;