import React from "react";
import { useState,useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {TextareaAutosize} from "@material-ui/core";
//import "./editDialog.css";
import "./editConfig.css"
import FormControl from "@material-ui/core/FormControl";
import { useAuthState } from "@bamboobox/b2logincheck";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {Typography} from "@material-ui/core";
import { configCreation,getConfig } from "../../../api";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
const filter=createFilterOptions();
const useStyles = makeStyles((theme) => ({
  largeBtn: {
    width: 140,
  },
  root : {
    width: "500px",
    marginLeft:45
  },
  formControl: {
    //margin: theme.spacing(1),
    margin:"0.8rem",
    width: 200,
    marginLeft: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paraV:{
    color:"#ff0000",
    display:"block"
  },
  paraH:{
    display:"none"
  },
}));

export default function EditDialog(props) {
  //console.log("props",props);  
  const classes = useStyles();
  const [values,setValues]=useState(props.configVal);
  const [open, setOpen] = React.useState(false);
  const [showError,setShowError]=useState(false);
  const userData = useAuthState().user;
  const [msg,setMsg]=useState("");
  const tenantId=userData.tenantId;
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleClose = (e) => {
    if(e._reactName === "onClick"){
      setOpen(false);
    }
    setValues(props.configVal)
    
  };

  const handlechange=(prop)=>{
    if(prop.target.value===""){
      setShowError(true);
    }else{
      setShowError(false);
    }
    setValues(prop.target.value);
  }

  const handleClickOpen=()=>{
    setOpen(true);
  }

  const handleSaveClick=async()=>{
    if(!values){
      setMsg("config values are required")
      setShowError(true);
    }else{
      const requestOptions = {
        method: 'POST',
        credentials:"include",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ rowId:props.rowId,configVal:values.trim() })
    };
    try {
      const response=await fetch(window._env_.TENANT_CONFIG+'/tenant/updateTenantConfig', requestOptions)
    const data=await response.json();
    props.renderHandler();
    setOpen(false);
    } catch (error) {
      console.log(error);
      setOpen(false);
      alert("Update failed");
    }
    }
    
  }

  return (
    <div>
        <IconButton onClick={handleClickOpen}>
            <EditIcon style={{color:"rgb(33, 218, 140)"}}  />
        </IconButton>
      <Dialog
        open={open}
        fullScreen
        onClose={e=>handleClose(e)}
        aria-labelledby="alert-dialog-title edit"
        aria-describedby="alert-dialog-description edit"
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="alert-dialog-title" style={{marginLeft:"45px"}}>{"Edit Config"}</DialogTitle>
        <DialogContent className={classes.root} >
          <DialogContentText id="alert-dialog-description">
            <form >
              <div>
                <FormControl variant="outlined" className={classes.formControl}>
                 <label style={{color:"#212121"}}>Tool</label>
                 <TextField
                    disabled
                    id="outlined-disabled"
                    defaultValue={props.tool}
                    style={{width:200}}
                />  
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <label style={{color:"#212121"}}>ConfigKey</label>
                    <TextField
                        disabled
                        id="outlined-disabled"
                        defaultValue={props.configKey}
                        style={{width:200}}
                    />
                </FormControl>
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                   <div>
                   <label style={{color:"#212121"}}>Config-Values</label>
                    <TextareaAutosize 
                    aria-label="minimum height" 
                    minRows={7 }
                    value={values} 
                    onChange={(prop)=>handlechange(prop)} 
                    style={{ width: 400 }}
                    />
                   </div>
              </FormControl>
              <Typography className={showError?classes.paraV:classes.paraH} component={'span'} variant={'body2'}>{msg}</Typography>
              <DialogActions style={{marginTop:"20px",justifyContent:"space-around" ,width:"400px"}}>
                <Button
                className={classes.largeBtn}
                  onClick={handleClose}
                  style={{  fontSize: "12px",
                          backgroundColor: "#DDDDDD",
                          borderColor: "rgb(33, 218, 140)",
                          color: "black",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}
                >
                  Close
                </Button>
                <Button
                className={classes.largeBtn}
                  onClick={handleSaveClick}
                  variant="contained"
                  style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
