import React from 'react';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './editActions.css';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Select from "@material-ui/core/Select";
import Checkbox from '@material-ui/core/Checkbox';
import { editActions } from '../api';
import { TextField } from "@mui/material";

const useStyles = makeStyles(theme => ({
  form:{
    display:"flex",
    flexDirection:"column",
    marginLeft:"20px"
  },
  largeBtn: {
      width: 120
  },
  formControl: {
    margin: theme.spacing(1),
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginLeft: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  superUser:{
    display:"flex",
    alignItems:"center",
    marginBottom:"10px"
  },
  buttonsHolder:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginTop:"20px"
  },
  textRoot:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    width:"80vw",
    margin:"20px auto"
  },
  col:{
    marginBottom:"10px"
  },
  dialogContent:{
    margin: "auto",
    marginTop: "15px",
  },
 }));

const EditActionLabels = (props)=> {
  console.log(props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [actionDetails, setActionDetails] = useState(props.actionDetails);
  const [actionLabel,setActionLabel]=useState(props.actionLabel);
  const [actionName,setActionName]=useState(props.actionName);
  const [serviceName, setServiceName] = useState(props.serviceName);
  const [serviceBaseUrl, setServiceBaseUrl] = useState(props.serviceBaseUrl);
  const [publicVisibility,setPublicVisibility]=useState(props.publicVisibility);
  const [nameError,setNameError]=useState(false);
  const [nameText,setNameText]=useState("");
  const [supportedType,setSupportedType]=useState(props.supportedType);
  const [color,setColor] =useState("#000000");
  const [disable,setDisable]=useState(false);
  // const [serviceId,setServiceId]=useState(props.serviceId);
  // const [showError,setShowError]=useState(false);
  // const [msg,setMsg]=useState("");
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleServiceName=(e)=>{
    if(e.target.value===""){
      setNameError(true);
      setNameText("required")
    }else{
      setNameError(false);
      setNameText("");
    }
    setServiceName(e.target.value)
  }

  const handleSave=async()=>{
    const data={
      id:props.id,
      actionName:actionName,
      actionLabel:actionLabel,
      serviceId:props.serviceId,
      actionDetails:JSON.parse(actionDetails),
      serviceName:serviceName,
      serviceBaseUrl:serviceBaseUrl,
      publicVisibility:publicVisibility,
      supportedType:supportedType
    }
    const response=await editActions(data);
    props.renderHandler();
    setOpen(false);
 }

  const handleClose = (e) => {
    if(e._reactName === "onClick"){
      setOpen(false);
    }
  };

  const handlechange=(e)=>{
    //console.log(e.target.value)
    try {
            JSON.parse(e.target.value)
            setDisable(false)
            setColor("#000000")
        } catch (error) {
            setDisable(true)
            setColor("#990000")
        }
        setActionDetails(e.target.value)
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <div>
      <IconButton onClick={handleClickOpen}>
          <EditIcon style={{color:"rgb(33, 218, 140)"}} />
      </IconButton>
      <Dialog
        open={open}
        onClose={e=>handleClose(e)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="EditActions"
      >
        <div className={classes.dialogContent}>
        <DialogTitle id="alert-dialog-title">{"Edit User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">  
          <div>
                <FormControl >
                <label style={{color:"#212121"}}>Service-Name</label>
                 <TextField
                    id="outlined-disabled"
                    value={serviceName}
                    onChange={e=>handleServiceName(e)}
                    error={nameError}
                    helperText={nameText}
                    style={{width:500}}
                    variant="standard"
                />
                </FormControl>
              </div>
              {/* <div>
                <FormControl >
                <label style={{color:"#212121"}}>Service-Id</label>
                 <TextField
                    id="outlined-disabled"
                    value={serviceId}
                    onChange={e=>setServiceId(e.target.value)}
                    style={{width:500}}
                    variant="standard"
                />
                </FormControl>
              </div> */}
              <div>
                <FormControl  >
                <label style={{color:"#212121"}}>serviceBaseUrl</label>
                 <TextField
                    id="outlined-disabled"
                    value={serviceBaseUrl}
                    onChange={e=>setServiceBaseUrl(e.target.value)}
                    style={{width:500}}
                    variant="standard"
                />
                </FormControl>
              </div>
              <div>
                <FormControl required={true} >
                <label style={{color:"#212121"}}>Action-Name</label>
                 <TextField
                    id="outlined-disabled"
                    value={actionName}
                    onChange={e=>setActionName(e.target.value)}
                    style={{width:500}}
                    variant="standard"
                />
                </FormControl>
              </div>
              <div>
                <FormControl required={true} >
                <label style={{color:"#212121"}}>Action-Label</label>
                 <TextField
                    id="outlined-disabled"
                    value={actionLabel}
                    onChange={e=>setActionLabel(e.target.value)}
                    style={{width:500}}
                    variant="standard"
                />
                </FormControl>
              </div>
            <div style={{margin:"5px 0px"}}>
                <InputLabel id="demo-simple-select-outlined-label" style={{display:"inline" ,color:"#212121"}}>
                        publicVisibility:
                </InputLabel>
                <Checkbox color="primary" checked={publicVisibility} onChange={e=>setPublicVisibility(!publicVisibility)} />
            </div>
            <div>
                  <InputLabel id="demo-simple-select-outlined-label" style={{color:"#212121"}}>
                    supportedType
                  </InputLabel>
                  <Select
                    style={{width:"300px"}}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={supportedType}
                    onChange={e=>setSupportedType(e.target.value)}
                    label="SupportedType"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="account">account</MenuItem>
                    <MenuItem value="contact">contact</MenuItem>
                  </Select>
              </div>
              <div >
              <FormControl variant="outlined" >
                   
                   <label style={{color:"#212121"}}>Action-Details</label>
                    <TextareaAutosize 
                    aria-label="minimum height" 
                    minRows={4}
                    value={actionDetails} 
                    onChange={(e)=>handlechange(e)}
                    className="textarea1" 
                    style={{ padding: "20px 40px", borderColor: "rgba(118, 118, 118, 0.3)", color:`${color}` }}
                    />
              </FormControl>
              {/* <Typography className={showError?classes.paraV:classes.paraH} component={'span'} variant={'body2'}>{msg}</Typography> */}
        </div>  

        <DialogActions className={classes.buttonsHolder}>
          <Button className={classes.largeBtn} onClick={handleClose}  variant="contained"  style={{  fontSize: "12px",
                          backgroundColor: "#DDDDDD",
                          borderColor: "rgb(33, 218, 140)",
                          color: "black",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}>
            Close
          </Button>
          <Button disabled={disable} className={classes.largeBtn} onClick={handleSave} variant="contained" style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"10px",
                          padding:"8px 10px"
                      }}>
            Save
          </Button>
        </DialogActions>
          </DialogContentText>
        </DialogContent>
        </div>
      </Dialog>
</div>
  );
}

export default EditActionLabels;