import { Button } from '@mui/material'
import React from 'react'

const BulkCreate = (props) => {
  return (
    // <Button variant="contained" color="primary" style={{marginLeft:'45%'}} onClick={() => props.setshowBulkScreen(true)}>{props.children}</Button>
    <Button variant="contained" onClick={() => props.setshowBulkScreen(true)} style={{  fontSize: "12px",
                          backgroundColor: "rgb(33, 218, 140)",
                          borderColor: "rgb(33, 218, 140)",
                          color: "rgb(255, 255, 255)",
                          borderRadius: "18px",
                          marginRight:"30px"
                      }} >
        Bulk User Creation
      </Button>
  )
}

export default BulkCreate